@media (max-width: 992px) {

	body {
		font-size: 16px;
	}

	h1 {
		font-size: 22px;
	}

	h2 {
		font-size: 20px;
	}

	h3 {
		font-size: 18px;
	}

	form {
		margin-top: 15px;
	}

	.inner-content-body h1 {
		font-size: 20px;
	}

	.inner-content-body h2 {
		font-size: 18px;
	}

	.inner-content-body h3 {
		font-size: 16px;
	}

	.inner-top-navbar {
		display: none !important;
	}

	.inner-content-body {
	}

	.small-logo img {
		max-width: 220px;
	}

	.navbar {
		height: 100%;
	}

	//make the BG white for responsive full height look
	.supplier-body-background {
		background-color: #FFF;
	}

	#personal-menu, #mobile-menu {
		display: flex;
		float: right;
		padding: 0.25rem 0.5rem;
	}

	#personal-menu .icons {
		height: 25px;
		margin-top: 2px;
	}

	.content-body {
		min-width: 100%;
		width: 100%;
		padding: 70px 0 60px;
	}
	.desktop-header-button {
		display: none;
	}

	.content-block-header-row {
		margin-bottom: 0;
	}

	.content-block-header {
		background: white;
		border: none;
		padding: 45px 45px 0 45px;
	}

	/* Modal */
	.modal {
		padding: 70px 0 0 0;
	}

	/* Modal backdrop */
	.modal-backdrop {
		background-color: black;
		opacity: 1;
	}

	.modal-backdrop.show {
		opacity: 0.2;
	}

	/* Modal header */
	.modal-header {
		.close {
			font-size: xx-large;
		}
	}

	/* Modal body */
	.modal-body {
		padding: 2px 16px;
	}

	/* Modal footer */
	.modal-footer {
		display: none;
		position: fixed;
		bottom: 0;
		left: 0;
		justify-content: center;
		width: 100%;
		background-color: white;
		z-index: 1050;
		padding: 0.5rem;
		-webkit-box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.2);
		-moz-box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.2);
		box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.2);

		button {
			margin: 0.25rem 0;
		}

		.close {
			opacity: 1;
		}
	}

	.modal.show .modal-footer {
		display: flex;
	}

	/* Modal content */
	.modal-content {
		position: relative;
	}

	.slide-down {
		top: -1000px;
		-webkit-transition: 1.5s all 0s;
		-moz-transition: 1.5s all 0s;
		-ms-transition: 1.5s all 0s;
		-o-transition: 1.5s all 0s;
		transition: 1.5s all 0s;
		display: block !important;
	}

	/* Modal close animation */
	.slide-down.show {
		opacity: 1;
		top: 0;
	}

	/* Modal dialog */
	.modal-dialog {
		margin: 0;
		padding-bottom: 60px;
	}

	.header-flash-message-wrap {
		padding-left: 15px;
		padding-right: 15px;
	}
	.header-flash-message {
		padding-left: 15px;
		padding-right: 15px;
		margin-left: 0 !important;
		margin-right: 0 !important;
		margin-bottom: 0;
	}

	#admin_button {
		position: fixed;
		float: none !important;
		right: 160px;
		top: 0;
		color: white;
		background-color: #838383;
		border-radius: 5px;
		z-index: 1020;
	}

    .contract-product-attribute-title {
        width: unset;
	    clear: both;
    }

    .contract-product-attribute {
	    width: unset;
    }

	.switches .form-group {
		margin-bottom: 10px;
		display: block;
	}

	.go-back a {
		color: black;
		text-decoration: none;
	}

	.icon-go-back {
		font-size: 14px;
		margin-right: 10px;
	}

	.content-card {
		margin: 0 0 20px 0;
		padding: 30px 45px;

		h1 {
			font-weight: bold;
		}
	}

	.action-icon.action-active {
		color: #5faa1a;
	}

	.action-icon.action-active .icons {
		color: #5faa1a;
	}

	.icons {
		height: 22px;
	}

	input[type=file] {
		font-size: 12px;
	}

    #loading-bar {
        height: 2px;
        position: absolute !important;
        top: 70px;
        width: 100%;
        left: 0;
        margin: 0;
    }

	.dropdown-menu-maxed {
		text-align: center;
		width: 100%;
	}

	.alert {
		margin-left: -5px;
		margin-right: -5px;
		margin-top: 0;
		padding: 15px;
	}
}

@media only screen and (max-width: 768px) {
	.content-block-header {
		h2 {
			width: 100%;
		}
	}
	.modal-content {
		border: none;
	}
}

@media (max-width: 575px) {
	.col-12.btn {
		margin-top: 15px;
	}
}

@media (min-width: 575px) and (max-width: 992px) {
	.modal-dialog {
		max-width: unset;
	}
	.content-card {
		margin-left: 50px;
	}}

@media (min-width: 768px) {
	#slide-down {
		padding: 0 30px;
	}
}


@media (min-width: 992px) and (max-width: 1200px) {
	.content-card {
		padding: 30px;
		max-width: 630px;
	}
}

@media (max-width: 1200px) {
	.table {
		font-size: small;
	}
}
