.pagination {
	justify-content: center;
}

.pagination-title-info {
	font-size: 12px;
	padding-top: 14px;
	padding-left: 20px;
}

.pagination-hr-t {
	margin: 0;
	margin-bottom: 8px;
}

.pagination-hr-b {
	margin-top: 8px;
}

.pagination-wrap {
	float: right;
	padding-top: 5px;
	padding-bottom: 5px;
}

@media only screen and (max-width: 768px) {
	.pagination {
		justify-content: center;
	}

	.pagination-title-info {
		display: none;
	}

	.pagination-hr-t {
		display: none
	}

	.pagination-hr-b {
		display: none;
	}

	.pagination-wrap {
		float: none;
	}

	.page-item:first-child {
		margin-right: auto;
	}

	.page-item:last-child {
		margin-left: auto;
	}
}

.page-item:first-child .page-link, .page-item:last-child .page-link {
	border-radius: 0;
	background-color: #fdfdfd !important;
	border-bottom: none !important;
}

.page-link {
	position: relative;
	display: block;
	padding: 0.5rem 1rem;
	margin-left: -1px;
	line-height: 1.25;
	color: #707070;
	background-color: #FFF;
	border: none;
}

.page-item.disabled .page-link {
	color: #c5c5c5;
	pointer-events: none;
	cursor: auto;
	background-color: white;
}

.page-item.active .page-link {
	z-index: 1;
	font-weight: bold;
	color: #555;
	background-color: white;
	border-bottom: 2px solid #5faa1a;
}

.page-link:hover {
	color: black;
	text-decoration: none;
	border-bottom: 2px solid #5faa1a;
}

.page-link:focus {
	color: black;
	text-decoration: none;
	border-bottom: 2px solid #CCC;
	box-shadow: none;
	background-color: unset;
}
