.bg-inner-nav {
	background-color: #efefef;
}

.navbar-nav li {
	font-size: 15px;
}

.navbar-nav li a {
	font-weight: 400;
}

.navbar .icons {

}

