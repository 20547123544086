.costs-product-wrap {
	padding: 10px;
	background: #efefef;
	border: 1px #dedede solid;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	margin-bottom: 30px;
}

.costs-product-attribute-title {
	font-weight: bold;
	font-size: 12px;
}

.costs-product-attribute {
	font-size: 12px;
}

.costs-product-row {
	padding: 0;
	line-height: 20px;
}

.costs-product-icon {
	font-size: 50px;
}

.costs-product-icon-title {
	display: block;
	width: 100%;
	font-size: 12px;
}

#container_graph_stroom, #container_graph_gas {
	width: 100%;

	#drilldown_summary_electricity, #drilldown_summary_gas, #month_summary_electricity, #month_summary_gas {
		font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
		font-size: 12px;
		color: rgb(51, 51, 51);
		background-color: white;
		line-height: normal;
		position: absolute;
		display: none;
		right: 0;
		bottom: 55px;
		border: 2px solid #EFEFEF;
		border-right: none;
		margin: 5px 0;
		padding: 5px 7px;
		min-height: 130px;
		min-width: 175px;

		.drilldown_summary_title {
			margin: 2px 0 5px 0;
		}

		.drilldown_summary_body {
		}

		.drilldown_summary {
			display: none;
			min-width: 100%;
		}
	}

	#month_summary_electricity, #month_summary_gas {
	}
}

@media (max-width: 1200px) {

}

@media (max-width: 992px) {
	.costs-product-wrap {
		background: #efefef;
		border: none;
		border-radius: unset;
		margin-left: -30px;
		margin-right: -30px;
		padding: 30px;
	}

	#elec_graph, #gas_graph {
		border: none;
		background: transparent;
	}
}

@media (max-width: 768px) {
	.costs-product-attribute-title, .costs-product-attribute {
		font-size: 13px;
		margin-left: 0;
		vertical-align: middle;
	}
	.costs-product-attribute-title {
		margin-left: 5px;
	}
	.costs-product-row {
		padding: 0;
	}

	#container_graph_stroom.costs-chart, #container_graph_gas.costs-chart  {

		#drilldown_summary_electricity, #drilldown_summary_gas {
			bottom: 25px;
		}
	}
}
