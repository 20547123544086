/***
* Progressive Webapp styling
***/

#pwa-ios-install {
	position: fixed;
	left: 10px;
	right: 10px;
	bottom: 10px;
	background-color: white;
	padding: 20px;
	border: 2px solid black;
}

/* It targets only the app used within the browser */
@media (display-mode: browser) {

}
/* It targets only the app used with a system icon in standalone mode */
@media (display-mode: standalone) {

}
/* It targets only the app used with a system icon in all mode */
@media (display-mode: standalone), (display-mode: fullscreen), (display-mode: minimal-ui) {
	#add-to-home-screen-ios {
		display: none !important;
	}
}
