.content-tab {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 20px;
}

@media (max-width: 992px) {
    .content-tab {
        padding-left: 0;
        padding-right: 0;
        margin-top: 20px;
    }
}
