
.sidebar-background {
	background: #fdfdfd;
}

.desktop-side-menu {
	$list-item-height: 100px;
	list-style-type: none;
	margin: 0 !important;
	padding: 0;

	margin-block-end: 0;
	margin-block-start: 0;

	//display:inline-block;
	width: 100%;

	li {
		cursor: pointer;
		overflow: hidden;
		height: $list-item-height;
	}

	li:nth-child(odd) {
		background: #fafafa
	}

	li:nth-child(even) {
		background: #efefef;

	}

	li:hover {
		-moz-transition: all .2s ease-in;
		-o-transition: all .2s ease-in;
		-webkit-transition: all .2s ease-in;
		transition: all .2s ease-in;
		//background: #e0dec0 ;

		filter: brightness(90%);
	}

	li.span {

	}

	li span.link-wrap {
		//display:block;
		width: 68%;
		//background:red;
		float: left;
		height: $list-item-height;
		padding-left: 15px;
		font-size: 14px;
		font-weight: 600;
		color: #000;

		display: flex;
		align-items: center;
	}

	li span.icon-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		//background:green;
		width: 32%;
		float: left;
		text-align: center;
		height: $list-item-height;
		line-height: 70px;
		padding: 15px 15px 15px 10px;
		margin: 0;
	}

	li span.icon-wrap svg {
		background: #666;
		background: rgba(0, 0, 0, 0.4);
		border-radius: 100%;
		border: 3px solid #fff;
		box-shadow: 0 1px 10px rgba(0, 0, 0, 0.16);
		color: #fff;
		display: table-cell;
		font-size: 30px;
		padding: 15px;
		text-align: center;
		transition: .5s;
		vertical-align: middle;
		width: 67px;
		height: 67px;
		margin: 0 auto;
		position: absolute;
		box-sizing: border-box;
	}

	.nav-link {
		padding: 0;
	}

	.nav-admin-only {
		opacity: 0.2;
	}
}

.navbar-nav .nav-link {
	float: left;
}

.navbar-nav .nav-salesperson .nav-link, .desktop-side-menu .nav-salesperson .nav-link .link-wrap {
	color: #417541;
}
.navbar-nav .nav-salesperson .nav-link:hover, .desktop-side-menu .nav-salesperson .link-wrap .nav-link:hover {
	color: #245f24;
	text-decoration: none;
}
.navbar-nav .active.nav-salesperson .nav-link {
	color: #118c11;
}
.navbar-nav .nav-salesperson {
	background-color: #fafafa;
}

@media only screen and (max-width: 1199px) {
	.desktop-side-menu {
		li span.link-wrap {
			//display:block;
			width: 73%;
			//background:red;
			float: left;
			padding-left: 8px;
			font-size: 13px;
			font-weight: 600;
			color: #000;


			display: flex;
			align-items: center;
		}

		li span.icon-wrap {
			//background: green;
			width: 27%;
			//padding: 30px 15px 30px 0;
			//margin: 0;
		}

		li span.icon-wrap svg {
			background: #666;
			background: rgba(0, 0, 0, 0.4);
			border-radius: 100%;
			border: 3px solid #fff;
			box-shadow: 0 1px 10px rgba(0, 0, 0, 0.16);
			color: #fff;
			display: table-cell;
			font-size: 10px !important;
			padding: 6px !important;
			text-align: left !important;
			transition: .5s;
			vertical-align: middle;
			width: 40px !important;
			height: 40px !important;
			margin: 0 auto;
			box-sizing: border-box;
		}
	}
}

@media only screen and (max-width: 992px) {
	.desktop-side-menu {
		li span.link-wrap {
			width: 60%;
		}

		li span.icon-wrap {
			width: 40%;
		}
	}

	.navbar-nav .dropdown.show .nav-link {
		background-color: unset;
		border: 2px solid transparent;
	}
}
