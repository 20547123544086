// Fonts
//@import url('https://fonts.googleapis.com/css?family=Nunito');


// Paths
$img-path: "/themes/eabo-v2/img" !default;
$fonts-path: "/themes/eabo-v2/fonts" !default;


// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// plugins
@import "~animate.css/animate.min.css";
@import '~pretty-checkbox/src/pretty-checkbox.scss';
@import 'plugins/bootstrap-touchspin/jquery.bootstrap-touchspin.css';
@import '~bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css';

// fontawesome
@import '~@fortawesome/fontawesome-free/scss/fontawesome';

// css helpers
@import 'css_helpers';

// global styles
@import 'global';

/*
 * Start: NAVIGATION
 */

// Admin bar
@import 'nav/admin-bar/admin_bar';

// Navigation menu: top menu
@import 'nav/top-menu/top_menu';

// Navigation menu: Header Nav (main menu in header)
@import 'nav/mobile-nav/mobile-nav.scss';

// Navigation menu: Header INNER subNav (sub header menu in pages)
@import 'nav/inner-header-subnav/inner_header_subnav';

// Navigation menu: inner side nav (side menu in pages)
@import 'nav/inner-sidenav/inner_side_nav';

/*
 * End: NAVIGATION
 */

// page sections
@import 'sections/global';
@import 'sections/admin';
@import 'sections/auth';
@import 'sections/overview';
@import 'sections/invoices';
@import 'sections/contracts';
@import 'sections/usage';
@import 'sections/readings';
@import 'sections/costs';
@import 'sections/submit_readings';
@import 'sections/salesperson';
@import 'sections/changes';
@import 'sections/documents';

// page components
@import 'nav/tabset/tabset';

// tables
@import 'tables/usage_tables';
@import 'tables/list';

// form components
@import 'form/form';
@import 'form/buttons';
@import 'form/pagination';

// alert components
@import 'alerts/header-flash';

// responsive overrides
@import 'responsive';

// progressive webapp
@import 'pwa';

// Debug
@import 'debug';


//override background when background image is set
.supplier-body-background-image {
	-webkit-background-size: cover !important;
	-moz-background-size: cover !important;
	-o-background-size: cover !important;
	background-size: cover !important;
	background-position-y: 79px !important;
}

