@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);

html {
	overflow: auto;
	height: 100%;
}

body {
	height: 100%;
	overflow: auto;
	overflow-x: hidden;
	transition: background-color .5s ease-in-out;
    line-height: 1.5em;
}

sub, sup {
	/* Specified in % so that the sup/sup is the
	   right size relative to the surrounding text */
	font-size: 75%;

	/* Zero out the line-height so that it doesn't
	   interfere with the positioning that follows */
	line-height: 0;

	/* Where the magic happens: makes all browsers position
	   the sup/sup properly, relative to the surrounding text */
	position: relative;

	/* Note that if you're using Eric Meyer's reset.css, this
	   is already set and you can remove this rule */
	vertical-align: baseline;
}

sup {
	/* Move the superscripted text up */
	top: -0.5em;
}

sub {
	/* Move the subscripted text down, but only
	   half as far down as the superscript moved up */
	bottom: -0.25em;
}

form {
	margin-top: 15px;
}

a.anchor {
	display: block;
	position: relative;
	visibility: hidden;
}

#overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1001;
	background-color: rgba(255,255,255,0.7);
}

.overlay {
	visibility: hidden;
	opacity: 0;
	transition: visibility 2s, opacity 1s linear;
}

.overlay-show {
	visibility: visible;
	opacity: 1;
	transition: visibility 0.5s, opacity 0.5s linear;
}

/* Desktop header */
.desktoplogo {
	padding: 5px;
}

.desktoplogo a img {
	min-height: 40px;
	max-height: 65px;
	width: auto;
}

/* desktop */
.logo-fixed {
	height: 40px !important;
	width: auto
}

.wsmainfull {
	width: 100%;
	background-color: #fff;
	border-bottom: 1px solid #dedede;
}

.wsmainfull .desktoplogo {
	padding: 5px;
}

.wsmainwp {
	margin:0 auto;
	max-width:1140px;
	position:relative;
	min-height:70px
}

.desktoplogo {
	padding:16px 0 0;
	margin:0;
	float:left
}

.desktoplogo img {
	width:100%
}

.small-logo {
	max-height: 52px;
}

.small-logo img {
	max-height: 52px;
	width: auto;
}

/* Header block with Title & Subtitle & note*/
.content-block-header-row {
	margin-bottom: 15px;
}

.content-block-header {
	background: #fff;
	padding: 30px;
	min-height: 80px;

	h1, h2, h3 {
		margin: 0;
		display: block;
		width: 100%
	}

	h1 {
		font-size: 20px;
		text-transform: uppercase;
		font-weight: 400;
	}

	h2 {
		font-size: 14px;
		margin-top: 5px;
		width: 80%;
	}

	h3 {
		margin-top: 3px;
		font-size: 12px;
	}
}

.content-padding {
	padding: 30px;

	h1, h2, h3 {
		display: block;
		width: 100%;
		text-transform: none !important;
	}

	h1 {
		font-size: 20px;
		font-weight: bold;
	}

	h2 {
		font-size: 16px;
		margin-top: 0;
	}
}

.font-bold {
	font-weight: bold;
}

/* global body */
.content-background-color {
	background: #fff;
}

.content-body {
	opacity: 0;
	transition: opacity .5s ease-in-out;
	padding-top: 75px;
	padding-bottom: 75px;
}

/* Inner content body*/
.inner-content-body {
	font-size: 15px;

	h1, h2, h3 {
		margin: 10px 0;
		display: block;
		width: 100%
	}

	h1 {
		font-size: 18px;
		text-transform: uppercase;
	}

	h2 {
		font-size: 16px;
		font-weight: bold;
	}

	h3 {
		font-size: 14px;
		font-weight: bold;
	}
}

.inner-content {
	position: relative;
	min-height: 100vh;
	padding-top: 25px;
	padding-bottom: 45px;
}

.inner-content > div {
}

.max-width {
	max-width: 100%;
	width: 100%;
	flex: auto;
}

.alert {
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	border: solid 1px #D7D7D7;
	margin-bottom: 1.5rem;
}

.card {
	margin-bottom: 10px;
}

.inline-edit {
	text-decoration: underline dotted #bbb;
	display: inline-block;
	position: relative;
}

.inline-edit-active {
	text-decoration: none;
}

.inline-edit-link {
	cursor: pointer;
	width: 40px;
	padding: 0 5px 0 5px;
	position: absolute;
	opacity: 0.4;
	float: left;
	z-index: 1000;
}

.inline-edit-link:hover {
	opacity: 1;
}

.inline-edit-icon-active {
	color: green;
}

.admin-text {
	color: #b7b7b7;
}

.admin-text a {
	color: #8eadd7;
}

h2.admin-text {
    font-weight: bolder;
    font-size: 18px;
}

hr.test-emails {
	margin-bottom: 0;
}

.col-hr {
    font-weight: bold;
}

.input-icon {
	cursor: pointer;
	position: absolute;
	top: 8px;
	right: 10px;
}

.input-icon svg {
	color: #CCC;
	transition: color .4s ease-out;
}

.input-icon:hover svg {
	color: #000;
	transition: color .4s ease-in;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button.hide-spinner,
input::-webkit-inner-spin-button.hide-spinner {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number].hide-spinner {
	-moz-appearance: textfield;
}

.no-rounded-borders, .input-group > .form-control.no-rounded-borders {
	border-radius: 0;
}

.info-icon {
	color: #000;
	min-width: 22px;
	min-height: 22px;
}

.info-icon-top-right {
	display: inline;
	position: absolute;
	top: 2px;
	right: 15px;
}

.button-icon {
	color: #7c7c7c;;
	background-color: transparent;
}

.button-icon:hover {
	color: #a2a1a1;
}

.icon-wrap {
	min-width: 20px;
	display: inline-block;
}

button[disabled] .icons {
}

button[disabled].navbar-toggler .icons {
	background-color: unset;
}

.right {
	float: right;
}

.modal-header .close {
    font-size: x-large;
}

.modal .modal-header {
	padding: 1.5rem;
	box-shadow: 0 0 5px #888;
}

.modal .modal-footer {
	background-color: #efefef;
}

.modal .modal-body {
	margin-top: .2em;
	min-height: 6em;
}

.modal-body .row {
	margin-right: 0;
	margin-left: 0;
}

.alert-normal {
	background-color: #efefef;
}

.readonly-form {
	width: 100%;
	font-size: unset;
}

.readonly-form input {
	border: none;
	font-size: unset;
}

.readonly-form .form-group label {
	text-transform: none;
}

.readonly-form input.form-control {
	background-color: #f6f6f6;
}

.action-icon {
	color: black !important;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 25px;
	width: 25px;
	right: 0;
	position: absolute;
	top: 0px;
	border-radius: 50%;
}

.profile .action-icon {
	height: 24px;
	width: 24px;
	top: -4px;
}

.action-icon-white {
	color: white;
}

.action-icon svg {
	position: absolute;
	height: 100%;
}

.action-icon-border {
	border-bottom: 2px solid;
	border-radius: unset;
}

.action-icon a {
	color: black;
	text-decoration: none;
}

.action-icon.action-active {
	color: #5faa1a;
}

.action-icon.action-active .icons {
	color: #5faa1a;
}

.action-icon.action-active.no-items-found .icons {
	color: darkred;
}

.profile-bank .action-icon {
	color: white;
}

.profile-bank .form-group label, .profile-bank a {
	color: white;
}


.profile-bank .action-icon {
	color: white;
}

.content-card {
	padding: 30px;
	margin: 20px 0 20px 50px;
	max-width: 760px;
}

.center-left-content {
	position: relative;
	display: flex !important;
	justify-content: flex-start;
	align-items: center;
}

.center-right-content {
	position: relative;
	display: flex !important;
	justify-content: flex-end;
	align-items: center;
}

.center-content {
	position: relative;
	display: flex !important;
	justify-content: center;
	align-items: center;
}

.center {
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

#admin-manage-account #password-generator
{
	z-index: 1000;
}

.monospace {
	font-family: "Roboto", sans-serif;
}

.no-pointer {
	cursor: default !important;
}

.dropdown-menu {
    width: max-content;
	min-width: 8rem;
}

.dropdown-item {
	padding: 0.25rem 1.5rem 0.25rem 1rem;
	color: #212529 !important;
	cursor: pointer;
}

.dropdown-item-disabled {
	color: #888 !important;
	cursor: default;
}

.dropdown-title {
	padding: 0.25rem 1.5rem 0.25rem 1rem;
	color: #888 !important;
}

.dropdown-item:hover,
.dropdown-item:focus {
	background-color: #EEE;
}

.dropdown-item .dropdown-icon, #dropdownMenuButton .dropdown-icon {
	min-width: 25px;
	margin-right: 5px;
    margin-top: 1px;
	float: left;
	text-align: center;
}

.dropdown-item.disabled, .dropdown-item:disabled {
	color: #adb5bd !important;
	background-color: white;
	pointer-events: none;
}

.dropdown-search {
	width: 100%;
	padding: 0 1.5rem 0.35rem 1rem;
	margin-bottom: 10px;
	border-top: none;
	border-left: none;
	border-right: none;
}

.card-text {
	display: block;
}

.banner-spacing {
	height: 30px;
}

.icons {
	display: inline-block;
	position: relative;
	height: 16px;
	margin-left: 2px;
	margin-right: 2px;
}

.underline-dashed {
	text-decoration: underline 1px dashed #BBB;
	cursor: help;
}

/* pretty checkboxes */
.pretty.p-switch {
	margin-right: unset;
}

.pretty.p-switch .state label::before, .pretty.p-switch .state label::after {
	left: 2px;
}

.pretty.p-switch.p-fill input:checked ~ .state::before {
    border-color: forestgreen;
    background-color: forestgreen !important;
}

.pretty.p-switch.p-fill input:checked ~ .state label::after {
	left: 0.95em;
}

.p-switch.p-right {
	margin-right: unset;
	right: 0;
	position: absolute;
	top: 4px;
}

.salesperson-loaded-banner {
	position: absolute;
	text-align: center;
	top: 0;
	padding: 3px;
	width: 100%;
	background-color: gold;
	font-weight: bold;
	border-bottom: groove 2px gray;
	z-index: 9999;
}

.table th {
	border-top: none;
	border-bottom: none;
	vertical-align: middle;
	display: table-cell;
}

.table th a .th-sort-icon {
	display:none;
	position: absolute;
	right: -5px;
}

.table th a {
	display: block;
	text-decoration: none;
}

.table th a:hover .th-sort-icon {
	display: inline;
}

.table th a .th-sort-current {
	display: inline;
}

.table th a:hover .th-sort-current {
	display: none;
}

.table th a .th-sort-new {
	display: none;
}

.table th a:hover .th-sort-new {
	display: inline;
}

.hidden {
	display: none !important;
}

/* Progress bar */

#loading-bar {
	height: 2px;
	width: 100%;
	z-index: 1015;
}
#loading-bar.linear-progress {
	background: #bbdefb;
	height: 10px;
	position: relative;
	width: 50%;
	margin: 2rem auto 0;
	overflow: hidden;
	-webkit-animation: start 0.3s ease-in;
	animation: start 0.3s ease-in;
}
#loading-bar.linear-progress.small {
	width: 100%;
	height: 2px;
}
#loading-bar.linear-progress .bar {
	position: absolute;
	background: #1565c0;
	transition: transform 0.2s linear;
	left: 0;
	top: 0;
	bottom: 0;
	width: 100%;
}
#loading-bar.linear-progress .bar1 {
	-webkit-animation: progressLinearMovement 2.5s infinite;
	animation: progressLinearMovement 2.5s infinite;
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
}
#loading-bar.linear-progress .bar2 {
	left: -100%;
	-webkit-animation: progressLinearMovement 2.5s infinite;
	animation: progressLinearMovement 2.5s infinite;
	-webkit-animation-delay: 0.7s;
	animation-delay: 0.7s;
}
#loading-bar.linear-progress-material {
	background: #aad1f9;
	height: 10px;
	overflow: hidden;
	position: sticky;
	position: -webkit-sticky; /* Safari */
	top: 0;
}
#loading-bar.linear-progress-material.small {
	height: 2px;
}
#loading-bar.linear-progress-material .bar {
	position: absolute;
	background: #106cc8;
	transition: transform 0.2s linear;
	left: 0;
	top: 0;
	bottom: 0;
	width: 100%;
}
#loading-bar.linear-progress-material .bar1 {
	-webkit-animation: growBar1 2.5s infinite, moveBar1 2.5s infinite;
	animation: growBar1 2.5s infinite, moveBar1 2.5s infinite;
}
#loading-bar.linear-progress-material .bar2 {
	-webkit-animation: growBar2 2.5s infinite, moveBar2 2.5s infinite;
	animation: growBar2 2.5s infinite, moveBar2 2.5s infinite;
}
@-webkit-keyframes growBar1 {
	0% {
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
		transform: scaleX(0.1);
	}
	36.6% {
		-webkit-animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
		animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
		transform: scaleX(0.1);
	}
	69.15% {
		-webkit-animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098);
		animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098);
		transform: scaleX(0.83);
	}
	100% {
		transform: scaleX(0.1);
	}
}
@keyframes growBar1 {
	0% {
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
		transform: scaleX(0.1);
	}
	36.6% {
		-webkit-animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
		animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
		transform: scaleX(0.1);
	}
	69.15% {
		-webkit-animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098);
		animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098);
		transform: scaleX(0.83);
	}
	100% {
		transform: scaleX(0.1);
	}
}
@-webkit-keyframes moveBar1 {
	0% {
		left: -105.16667%;
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
	}
	20% {
		left: -105.16667%;
		-webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
		animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
	}
	69.15% {
		left: 21.5%;
		-webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
		animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
	}
	100% {
		left: 95.44444%;
	}
}
@keyframes moveBar1 {
	0% {
		left: -105.16667%;
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
	}
	20% {
		left: -105.16667%;
		-webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
		animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
	}
	69.15% {
		left: 21.5%;
		-webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
		animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
	}
	100% {
		left: 95.44444%;
	}
}
@-webkit-keyframes growBar2 {
	0% {
		-webkit-animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
		animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
		transform: scaleX(0.1);
	}
	19.15% {
		-webkit-animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
		animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
		transform: scaleX(0.57);
	}
	44.15% {
		-webkit-animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
		animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
		transform: scaleX(0.91);
	}
	100% {
		transform: scaleX(0.1);
	}
}
@keyframes growBar2 {
	0% {
		-webkit-animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
		animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
		transform: scaleX(0.1);
	}
	19.15% {
		-webkit-animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
		animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
		transform: scaleX(0.57);
	}
	44.15% {
		-webkit-animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
		animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
		transform: scaleX(0.91);
	}
	100% {
		transform: scaleX(0.1);
	}
}
@-webkit-keyframes moveBar2 {
	0% {
		left: -54.88889%;
		-webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968);
		animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968);
	}
	25% {
		left: -17.25%;
		-webkit-animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372);
		animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372);
	}
	48.35% {
		left: 29.5%;
		-webkit-animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203);
		animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203);
	}
	100% {
		left: 117.38889%;
	}
}
@keyframes moveBar2 {
	0% {
		left: -54.88889%;
		-webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968);
		animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968);
	}
	25% {
		left: -17.25%;
		-webkit-animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372);
		animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372);
	}
	48.35% {
		left: 29.5%;
		-webkit-animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203);
		animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203);
	}
	100% {
		left: 117.38889%;
	}
}
@-webkit-keyframes start {
	from {
		max-height: 0;
		opacity: 0;
	}
	to {
		max-height: 20px;
		opacity: 1;
	}
}
@keyframes start {
	from {
		max-height: 0;
		opacity: 0;
	}
	to {
		max-height: 20px;
		opacity: 1;
	}
}
@-webkit-keyframes end {
	from {
		max-height: 0;
		opacity: 0;
	}
	to {
		max-height: 2px;
		opacity: 1;
	}
}
@keyframes end {
	from {
		max-height: 0;
		opacity: 0;
	}
	to {
		max-height: 2px;
		opacity: 1;
	}
}
@-webkit-keyframes progressLinearMovement {
	0% {
		left: -100%;
	}
	50% {
		left: 100%;
	}
	100% {
		left: 100%;
	}
}
@keyframes progressLinearMovement {
	0% {
		left: -100%;
	}
	50% {
		left: 100%;
	}
	100% {
		left: 100%;
	}
}

/* End Progress bar */
