/** START: MyContract (Single)  **/
.contract-product-attribute-title {
	display: block;
	width: 40%;
	font-weight: bold;
	float: left;
}

.contract-product-attribute {
	display: block;
	width: 60%;
	float: left;
	padding-left: 1em;
}

.contract-product-row {
}

.contract-product-icon {
	font-size: 35px;
}

.contract-product-row .icon-wrap {
	width: 80px;
}

.contract-product-icon-title {
	display: block;
	width: 100%;
	font-size: 12px;
}

.contract-closed {
	.supplier-button-color {
		background: #919191 !important;
	}
	.supplier-border-color {
		border: 3px solid #919191 ;
	}
}

@media (max-width: 768px) {
	.contract-product-row {
		padding: 0;
	}
	.contract-product-view-button {
		margin-top: 10px
	}
	.contract-product-attribute-title, .contract-product-attribute {
		font-size: 13px;
	}
}

.responsive-card-table {
	border-collapse: collapse;
	width: 100%;
}

.responsive-card-table.unstriped tbody {
	background-color: transparent;
}

.responsive-card-table th {
	padding: 5px;
	font-weight: bold;
	font-size: 11px;
}

.responsive-card-table tr,
.responsive-card-table th,
.responsive-card-table td {
	/*border: 1px solid #0a0a0a;*/
	padding: 5px;
	font-size: 13px;

}

.responsive-card-table th {
	color: #000;
}

.responsive-card-table tr {
	color: darkgrey;
}

.responsive-card-table tr.highlight {
	/*background-color: #e6e6e6 !important;*/
	border: 1px dashed green;
	color: forestgreen !important;
}

@media screen and (max-width: 768px) {
	.responsive-card-table {

	}
	.responsive-card-table thead tr {
		position: absolute;
		top: -9999em;
		left: -9999em;
	}
	.responsive-card-table tr {
		border: 1px solid #0a0a0a;
		display: block;
	}
	.responsive-card-table tr + tr {
		margin-top: 1.5rem;
	}
	.responsive-card-table td {
		border: none;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-bottom: 1px solid #0a0a0a;
		padding-left: 50%;
		position: relative;
	}
	.responsive-card-table td:before {
		content: attr(data-label);
		display: inline-block;
		font-weight: bold;
		line-height: 1.5;
		margin-left: -100%;
		width: 100%;
		position: relative;
		z-index: 1;
	}
	.responsive-card-table td:after {
		content: '';
		position: absolute;
		background: #e6e6e6;
		width: 45%;
		height: 95%;
		left: 1px;
		top: 1px;
		z-index: 0;
	}
	.contract-products-list {
		padding-right: 15px !important;
	}
	.responsive-card-table tr.inactive-agreement {
		display: none;
	}
}

/** END: MyContract (Single)  **/


/** START: CONTRACTS LIST (MULTIPLE) PAGE **/
.contract-list {
	padding-left: 15px;
	padding-right: 15px;
	margin-top: -15px;
	margin-bottom: 10px;
}

.contract-line .contract-line-head {
	margin-top: 25px;
}

.contract-list-header-contract-nr {
	background: #efefef;
	font-size: 20px;
	padding-top: 5px;
	padding-bottom: 5px;
	text-align: center;
	font-weight: bold;
	-webkit-border-top-left-radius: 3px;
	-webkit-border-top-right-radius: 3px;
	-moz-border-radius-topleft: 3px;
	-moz-border-radius-topright: 3px;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}

.contract-list-header-contract-status {
	padding: 0;
	display: inline-block;
	font-size: 13px;
	margin-bottom: 0;
}

.contract-list-inner-body {
    line-height: 22px;
	background: #efefef;
	padding-top: 5px;
	padding-bottom: 5px;
	border-right: 0;
	border-top: 0;
}

.contract-list-inner-contract-header {
	display: block;
	width: 100%;
	font-weight: bold;
}

.contract-list-inner-contract-dates {
	display: block;
	width: 100%;
	font-size: 13px;
}

.contract-list-inner-contract-address {
	display: block;
	width: 100%;
	font-size: 13px;
}

.contract-search {
	width: 350px;
	padding: 5px 15px;
	margin: 10px 0 20px 0;
}

.contract-stopped {
	/*background:orangered;*/
	color: orangered;
}

.contract-prolong {
	/*background:forestgreen;*/
	color: forestgreen;
	cursor: pointer;
}

.contract-prolong:hover {
	-moz-transition: all .2s ease-in;
	-o-transition: all .2s ease-in;
	-webkit-transition: all .2s ease-in;
	transition: all .2s ease-in;
	filter: brightness(70%);
}

.icon-go-back {
	margin-left: 5px;
	margin-right: 5px;
}

@media (min-width: 576px) and (max-width: 677px) {
	.contract-list-header-contract-status {
		font-size: 11px;
		margin-bottom: 0;
	}
	.contract-list-view-button {
		font-size: 13px;
	}
}

@media (max-width: 768px) {
	.contract-list-header-contract-nr {
		font-size: 18px;
		font-weight: normal;
	}
	.contract-list-view-button {
		margin-top: 10px;
        margin-bottom: 10px;
	}
	.contract-list-inner-contract-header {
		margin-top: 5px;
	}
	.contract-list {
		padding-left: 15px;
		padding-right: 15px;
	}
	.contract-list-header-contract-status {
		font-size: 12px;
	}
}

@media only screen and (max-width: 992px) {
    .contract-list-inner-contract-header {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .contract-list-inner-contract-dates {
    }
}

/** END: CONTRACTS LIST (MULTIPLE) PAGE **/

/** GLOBAL CONTRACT ITEMS **/

.contract-card-attributes {
	padding-bottom: 20px;
}

.contract-card-attributes:last-child {
	padding-bottom: 0;
}

.contract-card-attribute-title {
	display: inline-block;
	vertical-align: top;
	margin-right: 5px;
	font-weight: bold;
}

.contract-card-attribute {
	display: inline-block;
}

.contract-card-status {
	position: absolute;
	width: 15px;
	height: 20px;
	left: -15px;
	top: 10px;
}

.contract-card-status-active {
	background-color: darkgreen;
}

.contract-card-status-inactive {
	background-color: darkred;
}

.contract-card-status-one-active {
	background-color: darkblue;
}

.contract-card-inactive {
	opacity: 0.5;
}

#contract-cards .content-card.selected .btn-primary:disabled {
	background-color: white;
	color: darkgrey;
}

/** END: GLOBAL CONTRACT ITEMS **/
