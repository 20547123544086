/** GLOBAL STYLING **/

body#webapp {
	background-color: white;
}

/** Switcher **/

.switchers {
	height: 100%;
	position: absolute;
	right: 30px;
	top: 0;
}

/** Contract switcher **/

#contract-switcher {
	font-size: 12px;
	margin-left: 10px;
	margin-top: 15px;
}

#contract-switcher #current-contract-info {
	display: flex;
	background: #EFEFEF;
	border: 1px solid #ccc;
	padding: 5px;
}

#contract-switcher #current-contract-address {
	display: none;
	margin: 5px 10px 5px 5px;
}

#contract-switcher #contracts-modal-overview {

}

#contract-switcher .row.selected, #card-switcher .row.selected {
	color: rgba(0, 0, 0, 0.5);
}


/** Customer card switcher **/

#card-switcher {
	font-size: 12px;
	margin-top: 15px;
}

#card-switcher #current-card-info {
	display: flex;
	background: #EFEFEF;
	border: 1px solid #ccc;
	padding: 5px;
}

#card-switcher #current-card-address {
	display: none;
	margin: 5px 10px 5px 5px;
}

#card-switcher #cards-modal-overview {

}

@media (max-width: 768px) {
	#card-switcher {
		float: left;
		position: relative;
		top: unset;
		right: unset;
		margin: 10px 0 0 0;
	}

	#card-switcher #current-card-address {
		display: inline-block;
	}
}

/** Contract states **/

.state_prolongable {
	color: green;
}

.state_quote {
	color: gray;
}

.state_closed {
	color: #b73333;
}

/** Generic styling **/

#dropdown_type {
	max-width: 130px;
}

.slide-down-color {
	background-color: #8c8c8c;
}

.content-card {
	background-color: lightgrey;
}

.content-card-dark {
	background-color: gray;
	color: white;
}

.content-card-dark .icons, .content-card-dark .action-icon {
	color: white !important;
}

.hash-selected {
	transition: background-color 2s ease-in-out;
	background-color: #EEE;
	margin: 15px -15px 15px -15px;
	padding: 0 15px;
	display: inline-block;
}

/** Overrule generic true mobile **/

div.container.content-body.is-mobile .inner-content-body > div[class^="col-"] {
	padding-left: 0;
	padding-right: 0;
}

/** Overrule true mobile lists/rows **/

.row.is-mobile {
	margin: 15px 0 5px;

	>:first-child {
		display: block;
		width: 100%;
		font-weight: 400;
		margin: 0 0 4px 0;
		padding: 0;
		font-size: 12px;
		color: #7c7c7c;
	}
	>:nth-child(2) {
		display: block;
		width: 100%;
		font-weight: 500;
		color: #495057;
		background-color: #f6f6f6;
		padding: 0.5rem 2em 0.5em 1em;
		font-size: 1.125rem;
		line-height: 1.5;
		margin: 0;
	}
	div[class^="col-"] {
		flex: unset;
		max-width: unset;
	}
}

/** global bootstrap tweaks **/

.badge {
	padding: 0.5em 1em;
	font-size: 80%;
	color: black;

}

.modal-body hr {
	margin-left: -15px;
	margin-right: -15px;
}

/** END: GLOBAL STYLING **/
