/*
 *  Bootstrap TouchSpin - v4.2.5
 *  A mobile and touch friendly input spinner component for Bootstrap 3 & 4.
 *  http://www.virtuosoft.eu/code/bootstrap-touchspin/
 *
 *  Made by István Ujj-Mészáros
 *  Under Apache License v2.0 License
 */
/* This CSS file is unnecessary if you are not using vertical buttons functionality */

.bootstrap-touchspin .input-group-btn-vertical {
	position: absolute;
	right: 0;
	height: 100%;
	z-index: 11;
}

.bootstrap-touchspin .input-group-btn-vertical > .btn {
	position: absolute;
	right: 0;
	height: 50%;
	padding: 0;
	width: 2em;
	text-align: center;
	line-height: 1;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
	border-radius: 0 4px 0 0;
	top: 0;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
	border-radius: 0 0 4px 0;
	bottom: 0;
}


