/** INVOICES **/

#invoices-table table, .invoices-table table {
	width: 100% !important;
}

#invoices-table .border-spacing, .invoices-table .border-spacing {
	border-collapse: separate;
	border-spacing: 0 5px;
}

#invoices-table .table td, #invoices-table .table th, .invoices-table .table td, .invoices-table .table th {
	border-top: none;
	border-bottom: none;
	vertical-align: middle;
	display: table-cell;
}

#invoices-table .table thead th, .invoices-table .table thead th {
	border-bottom: none;
	font-weight: bold;
	font-size: 15px;
	padding-top: 0;
	border-bottom: none;
}

#invoices-table .table td, .invoices-table .table td {
	background: #f6f7f7
}

#invoices-table .table tfoot td, .invoices-table .table tfoot td {
	background: unset;
	font-weight: bold;
	color: #d3d3d3 ;
}

#invoices-table .invoice-td-date {
	min-width: 120px
}

#invoices-table .invoice-td-total {
	min-width: 100px
}

#invoices-table .invoice-td-residual {
	min-width: 80px
}

.invoices-table .invoice-td-name {
	min-width: 100px;
	max-width: 100px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.invoices-table .invoice-td-percentage {
	width: 100px
}

.invoices-table .invoice-td-usage, .invoices-table .invoice-td-amount, .invoices-table .invoice-td-advance, .invoices-tabel .invoice-td-debit {
	width: 90px
}

#invoices-table .separate {
	border-left: 0 solid #fff;
	margin: 50px;
}

#invoices-table .invoice-button {
	line-height: 50px;
	padding: 0 2px;
}

#invoices-table .table td a, .invoices-table .table td a {
	width: 100%;
	display: block;
	color: #fff;
}

#invoices-table .table td a:hover, .invoices-table .table td a:hover {
	text-decoration: none;
}

#invoices-table .invoice-chevron {
	text-align: center;
	font-size: 0.8rem;
	line-height: 26px;
	min-width: 20px;
}

#invoices-table .status-button {
	border-left: 5px solid #fff;
	text-align: center;
	color: #fff;
	vertical-align: middle;
	padding: 0;
}

#invoices-table .payment-button {
	background: #bd4043;
	border-left: 5px solid #fff;
	text-align: center;
	color: #fff;
	vertical-align: middle;
	padding: 0;
}

#invoices-table .download-button {
	background: #bd4043;
	border-left: 5px solid #fff;
	color: #fff;
	text-align: center;
	border-left: 5px solid #fff;
	vertical-align: middle;
	padding: 0;
}

#invoices-table .link-button {
	display: block;
	width: 100%;
	padding-left: 8px;
	padding-right: 8px;
	line-height: 50px;
	-webkit-filter: brightness(100%);
}

#invoices-table .link-button:hover {
	-webkit-filter: brightness(85%);
	-webkit-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-o-transition: all 1s ease;
	-ms-transition: all 1s ease;
	transition: all 1s ease;
}

/** INVOICE CARDS (Mobile) **/

#invoices-table.invoices-cards {

	.table td {
		padding: 10px 0 10px 10px;
		color: black;
	}

	.table td a {
		color: black;
	}

	.invoice-td-bold {
		font-weight: bolder;
	}

	.invoice-td-actions {
		width: 50px;
	}

	.invoice-td-prices {
		width: 65px;
		white-space: nowrap;
	}

	.invoice-td-info {
		width: 60%;
	}

	.border-spacing {
		border-collapse: separate;
		border-spacing: 0 15px;
	}

	td.download-button {
		border-left: 0;
		padding-left: 15px;
	}

	td.invoice-td-actions {
		padding-right: 10px;
	}

	td.invoice-td-prices {
		text-align: right;
		padding-left: 20px;
		padding-right: 20px;
	}

	.invoice_state_open td {
		background-color: #e8efe8;
		color: black;
	}

	.invoice_state_open td a {
		color: black;
	}

	.invoice_state_paid td {
		background-color: #8bad67;
		color: white;
	}

	.invoice_state_paid td a {
		color: white;
	}

	.link-button {
		padding: 0 0 0 5px;
	}

	.ellipsis {
		 max-width: 134px;
	}

	.invoice-pay-now {
		color: #8bad67;
	}

}

@media (max-width: 992px) {
	#invoices-table .table td {
		font-size: 12px;
		padding: 0;
		height: 50px;
	}
	#invoices-table .table thead th {
		font-size: 12px;
		padding: 5px;
	}
	#invoices-table .invoice-td-date {
		min-width: unset;
		max-width: 100px;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	#invoices-table .invoice-td-total {
		min-width: 80px
	}
	#invoices-table .invoice-td-residual {
		min-width: 70px
	}
	#invoices-table .status-button {
		text-align: center;
		color: #fff;
		height: 47px;
		line-height: 30px;
	}
	#invoices-table .payment-button {
		text-align: center;
		color: #fff;
		height: 47px;
		line-height: 30px;
	}
}


@media (max-width: 768px) {
	#invoices-table .table .table-hide {
		display: none;
	}
}

#invoices-sub-connections .form-group {
	margin-right: 20px;
}

#invoice-amount-period #submit-button {
	width: 50%;
}

#invoice-amount-unit, #invoice-amount-period, #invoices-per-unit {
	padding: 10px 20px 20px 20px;
	border: 1px solid #ccc;
}

#invoices-per-unit {
	.amount-negative {
		color: red;
	}

	.generic-unit td {
		background-color: #c8d9c6;
	}

	.invoice-td-percentage {
		display: none;
	}

	.no-usage {
		color: #999;
	}
}

#edit-unit-table .invoice-td-percentage {
	.line-error {
		color: #bd9319;
		background: #faf0d5;
		border: 1px solid #f1dfb4;
	}
	.form-control.is-invalid {
		border-color: #c39d27;
	}
}

#invoices-sub-connections .flex-menu {
	display:flex; align-items: center;
}

#invoices-amount-unit-table table tr th {
	overflow: hidden;
	text-overflow: ellipsis;
}

#invoice-amount-unit-edit {
	.table th, .table td {
		padding: 0.5rem 0 0.5rem 0.5rem;
	}

	.table th:last-child, .table tbody td:last-child {
		padding: 0.5rem;
	}

	.table tfoot>tr>td {
		padding: 10px 0 0 20px;
	}

	.table tfoot>tr>td#totals_elec_adv, .table tfoot>tr>td#totals_gas_adv {
		padding: 10px 0 0 30px;
	}

	.input-group-text {
		padding: 0.375rem 0.3rem;
	}

	tfoot>tr>td.invoice-td-name {
		padding: 10px 0 0 10px;
	}

	tfoot .is-invalid {
		color: red;
		font-weight: normal;
	}

	tfoot .invoice-td-percentage.is-invalid {
		color: #E1B401;
		font-weight: normal;
	}

	input#unit_name {
		max-width: unset;
	}

	#invoices-edit-unit-table button {
		width: fit-content;
	}

	#invoices-edit-unit-table .form-group {
		margin-bottom: 0;
	}

	.invoices-table .invoice-td-name {
		min-width: 100px;
		max-width: unset;
	}

	.form-control.is-invalid {
		background: unset;
		padding-right: .5rem !important;
	}

	#edit-unit-table .form-group .input-group {
		width: 75px;
	}

	.invoice-td-name .form-group {
		min-width: 110px;
	}

	.invoice-td-meter-number .form-group {
		width: 90px;
	}

	.field-edited {
		font-weight: bolder;
	}

	.percentage-error, .input-error {
		color: #E1B401;
		font-weight: bolder;
	}

	.percentage-okay, .input-okay {
		color: green !important;
		font-weight: normal;
	}

	.generic-unit td {
		background-color: #c8d9c6;;
	}

	.form-control {
		padding: 0.375rem 0.4rem;
	}

	input {
		text-align: right;
	}
}

/** INVOICES **/
