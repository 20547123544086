@import url(https://fonts.googleapis.com/css?family=Open+Sans);

.usage {
	padding: 0;
}

.usage-product-wrap, .day-prices-wrap {
	padding: 10px;
	background: #efefef;
	border: 1px #dedede solid;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	margin-top: 15px;
	margin-bottom: 30px;
}

.usage-product-attribute-title {
	font-weight: bold;
	font-size: 12px;
	overflow: hidden;
}

.usage-product-attribute {
	font-size: 12px;
	overflow: hidden;
	margin-left: 5px;
}

.usage-product-row {
	padding: 0;
	margin-bottom: 10px;
}

.usage-product-icon {
	font-size: 50px;
}

.usage-product-icon-title {
	display: block;
	width: 100%;
	font-size: 12px;
}

.usage-chart {
	width: 100%;
	height: 400px;
}

.switches, .show_current_tariffs {
	font-size: 12px;
}

#elec_graph, #gas_graph {
	border: 1px solid #ccc;
	background: #fff;
	padding-top: 10px;
}

.graph-table {
	padding-top: 10px;
	overflow-y: visible;
	overflow-x: clip;
	margin-bottom: 10px;
	white-space: nowrap;
}

.advance-amount-warning {
	border: 2px solid #e6a3a9;
	padding: 5px 15px;
}

@media (max-width: 1200px) {

}

@media (max-width: 768px) {
	.usage-product-attribute-title, .usage-product-attribute {
		font-size: 13px;
		margin-left: 0;
		vertical-align: middle;
	}
	.usage-product-attribute-title {
		margin-left: 5px;
	}
}

@media (max-width: 390px) {
	#advice .dropdown {
		margin-top: 10px;
	}
}

@media (max-width: 992px) {
	.usage-product-row {
		margin: 0 0 5px 0;
		padding: 5px 0;
	}

	.usage-product-wrap, .day-prices-wrap {
		background: #efefef;
		border: none;
		border-radius: unset;
		margin-left: -30px;
		margin-right: -30px;
		padding: 30px 30px 0 30px;
	}

	.usage-product-row:nth-of-type(odd) {

	}

	.usage-product-row:nth-of-type(even) {
		background-color: rgba(255,255,255,0.6);
	}

	#elec_graph, #gas_graph {
		border: none;
		background: transparent;
	}

	.tabset {
		margin-top: 20px;
		margin-left: -30px;
		margin-right: -30px;
		border-top: 2px #dedede solid;
		border-radius: unset;
		padding: 10px 30px;
	}

	.tab-panels {
		margin-left: -30px;
		margin-right: -30px;
		padding: 0 10px 0 5px;
	}

	#day-prices .tab-panels {
		padding: 0 5px;
	}

	#elec_graph .usage-chart {
		height: 550px;
	}

	#gas_graph .usage-chart {
		height: 550px;
	}

	.graph-table {
		overflow-x: auto !important;
	}

}

// advice graph
.usage-advice-block-wrap {
	margin-top: 20px;
	overflow: hidden;
	width: 100%;
}

.usage-advice-product .icons {
	vertical-align: bottom;
}

.usage-advice-product-wrap {
	float: left;
	margin-top: 30px;
	margin-bottom: 30px;
	margin-left: 15px;
	color: black;
	width: 340px;
}

.usage-advice-product-wrap:first-of-type {
	margin-right: 15px;
}

.usage-advice-product-wrap-one-product {
	width: 75%;
}

.usage-advice-customer-wrap, .usage-costs-customer-wrap {
	overflow: auto;
}

.usage-advice-customer, .usage-costs-customer {
	float: left;
	width: 48%;
}

.usage-advice-customer-data, .usage-costs-customer-data {
}

.usage-advice-product-title {
	background: #eee;
	width: 100%;
	padding: 5px 10px;
	font-weight: bold;
}

.usage-advice-product {
	padding: 0;
	margin: 0;
	padding-top: 10px;
}

.usage-advice-product-bottom-info {
	background: #eee;
	padding: 5px 10px;
	width: 100%;
	text-align: right;
	font-weight: bold;
}

.usage-advice-product-bottom-missing-readings, .usage-advice-product-bottom-no-smart-meters {
	clear: both;
	background: #eee;
	margin-top: 10px;
	margin-bottom: 20px;
	padding: 5px 10px;
	width: 100%;
}

.usage-advice-product-bottom-no-smart-meters {
	margin-top: 40px;
}

.usage-advice-compare-wrap {
	overflow: hidden;
	width: 100%;
	color: black;
}

.usage-advice-compare-product {
	float: left;
	width: 50%;
	border: 1px solid #eee;
	padding-top: 10px;
}

.usage-advice-compare-product-title {
	background: #eee;
	width: 100%;
	padding: 5px 10px;
	font-weight: bold;
}

.usage-advice-compare-product-chart-height {
}

.usage-advice-compare-product-list {
	padding: 0;
	margin: 0;
	padding-top: 10px;
}

.usage-advice-compare-product-warning {
	background: #e6a3a9;
	padding: 10px;
	width: 100%;
	color: #721c24;
	text-align: center;
	font-size: 16px;
	line-height: 20px;
}

.usage-advice-compare-product-success {
	background: #8bca9b;
	padding: 10px;
	width: 100%;
	color: #025502;
	text-align: center;
	font-size: 16px;
	line-height: 20px;
}

h2.usage-advice-contractnr {
	margin-top: 30px !important;
	font-size: 18px;
	color: black;
}

h2.usage-advice-contractnr-multiple {
	margin-top: 60px !important;
	margin-bottom: 40px !important;
	padding: 20px;
	font-size: 20px;
	border: 1px solid black;
	box-shadow: 0 5px 10px #888;
}

@mixin stagger-anim-delay($i, $initial-delay, $stagger-delay) {
	@while $i > 0 {
		&:nth-of-type(#{$i}) {
			animation-delay: $initial-delay + $stagger-delay * $i;
		}
		$i: $i - 1;
	}
}

.usage_tips, .usage_compare {
	margin: 10px;
}

#usage-tips {
	h2 {
		font-weight: bold;
	}
}

.chart-wrap {
	width: 100%;
	margin: auto;
	position: relative;
	overflow: hidden;
	padding-bottom: 10px;
}

.usage-advice-product .chart-wrap {
	min-height: 305px;
}

.lines {
	height: 100%;
	width: 100%;
	position: absolute;
	padding: 0;
	margin: 0;
	top: 0;
	left: 0;
}

.line {
	height: inherit;
	width: 2px;

	position: absolute !important;

	background: rgba(#eee, 0.6);

	&.l--0 {
		left: 0;
	}

	&.l--25 {
		left: 25%;
	}

	&.l--50 {
		left: 50%;
	}

	&.l--75 {
		left: 75%;
	}

	&.l--100 {
		left: calc(100% - 1px);
	}
}

.line__label {
	display: block;
	width: 100px;
	text-align: center;

	position: absolute;
	bottom: -20px;
	right: -50px;

	&.title {
		text-transform: uppercase;
		font-weight: bold;
	}
}

.charts {
	width: 100%;
	height: 100%;
	position: absolute !important;
	top: 0;
	left: 0;
	z-index: 10;
	display: contents;
}

.chart {
	margin: 30px 0 0;

	&:first-child {
		margin: 0;
	}
}

.chart--horiz {
	overflow: hidden;
	margin: 0;
}

.chart__bar {
	$border-rad: 4px;
	padding: 2px 5px 4px 10px;
	line-height: 22px;
	margin-bottom: 10px;
	//background: linear-gradient(to left, #4cb8c4, #3cd3ad);
	background: #BDD6EE;
	border-top-right-radius: $border-rad;
	border-bottom-right-radius: $border-rad;
	opacity: 0;
	cursor: help;
	overflow: hidden;
	border: 1px solid #CCC;
	min-width: 195px;
	animation: 1s anim-lightspeed-in ease forwards;

	.chart--dev & {
		@include stagger-anim-delay(11, 0.5s, 0.2s);
	}

	.chart--prod & {
		@include stagger-anim-delay(8, 1s, 0.2s);
	}
}

.chart__text, .chart__background {
	position: absolute;
	top: 0;
	left: 0;
	background: none;
}

.chart__bar-last-g {
	background: #F9D8C1;
	min-width: 140px;
}

.chart__bar-last-e {
	background: #E1EFDA;
	min-width: 150px;
}

.chart__bar-last-year {
	background: #FFEAB4;
	min-width: 220px;
}

.usage-advice-compare-product-list .chart__bar-last-year {
	min-width: 300px;
}

.usage-advice-compare-product-list .chart__bar-last-e, .usage-advice-compare-product-list .chart__bar-last-g {
	min-width: 300px;
}

.usage-advice-compare-products:nth-child(2) {
	margin-top: 30px;
}

.chart__label {
	color: #000;
	text-decoration: 1px #eee dashed underline;
}

.chart__label-end {
	padding: 0 5px 0 1px;
	text-align: right;
	display: inline-block;
	float: right;
	background-color: inherit;
	color: black;
}

.chart-negative {
	color: #488c48
}

// Keyframes
@keyframes anim-lightspeed-in {
	0% {
		transform: translateX(-200%);
		opacity: 1;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@media only screen and (max-width: 768px) {
	.usage-advice-product-wrap {
		float: left;
		padding-left: 0 !important;
		width: 100% !important;
		padding-right: 30px;
		margin-left: 0 !important;
	}
	.usage-advice-product .chart-wrap {
		min-height: 270px;
	}
}

@media only screen and (min-width: 769px) {
	.usage-advice-product-wrap {
		width: 46% !important;
	}
}

.drilldown-calc {
	opacity: 0.5;
}

.drilldown-missing {
	opacity: 0.3;
}

#container_download_elec, #container_download_gas  {
	padding: 15px;
}

.highcharts-tooltip>span {
	background-color: #fff;
	border: 1px solid #888;
	border-radius: 5px;
	opacity: 0.9;
	z-index: 9999 !important;
	padding: .8em;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
	left: 0 !important;
	top: 0 !important;
}

.disclaimer {
	color:#9b9b9b;
	line-height: normal;
}

#container_graph_stroom, #container_graph_gas {
	position: relative;
}