.list table {
	width: 100% !important;
}

.list .border-spacing {
	border-collapse: separate;
	border-spacing: 0 5px;
}

.list .table td, .list .table th {
	border-top: none;
	border-bottom: none;
	vertical-align: middle;
	display: table-cell;
	padding-right: 5px;
}

.list .table thead th {
	border-bottom: none;
	font-size: 15px;
	padding-top: 5px;
	border-bottom: none;
	font-weight: bold;
}

.list .table td {
	background: #f6f7f7
}

.list .date {
	min-width: 100px
}

.list .nowrap {
	white-space: nowrap;
}

.list .table td.separate {
	border-left: 5px solid #fff;
	padding: 0;
	background-color: white;
}

.list .table td a {
	width: 100%;
	display: block;
	color: #fff;
}

.list .table td a:hover {
	text-decoration: none;
}

.list .chevron {
	text-align: center;
	font-size: 0.8rem;
	line-height: 26px;
	min-width: 20px;
}

.list .status-button {
	border-left: 5px solid #fff;
	text-align: center;
	color: #fff;
	vertical-align: middle;
	padding: 0;
}

.list .payment-button {
	background: #bd4043;
	border-left: 5px solid #fff;
	text-align: center;
	color: #fff;
	vertical-align: middle;
	padding: 0;
}

.list .download-button {
	background: #bd4043;
	color: #fff;
	text-align: center;
	border-left: 5px solid #fff;
	vertical-align: middle;
	padding: 0;
}

.list .link-button {
	display: block;
	width: 100%;
	padding-left: 8px;
	padding-right: 8px;
	line-height: 50px;
	-webkit-filter: brightness(100%);
}

.list .link-button:hover {
	-webkit-filter: brightness(85%);
	-webkit-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-o-transition: all 1s ease;
	-ms-transition: all 1s ease;
	transition: all 1s ease;
}

.list .separate {
	margin: 50px;
}

.pagination-menu {
	display: none;
}

@media (max-width: 992px) {
	.list .table td {
		font-size: 12px;
		height: 50px;
		max-width: 200px;
		overflow: hidden;
		padding: 5px 5px 5px 10px;
	}
	.list .table thead th {
		font-size: 12px;
	}
	.list .date {
		min-width: 80px
	}
	.list .status-button {
		text-align: center;
		color: #fff;
		height: 47px;
		line-height: 30px;
	}
	.list .download-button {
		border-left: unset;
	}
	.list .table td.separate {
		border-left: 0;
		background-color: inherit;
	}
	.ellipsis {
		max-width: 100%;
		white-space: normal;
	}
}

@media (max-width: 768px) {
	.list .table .table-hide, .list .table th.table-hide {
		display: none !important;
	}
	.pagination-menu {
		display: block;
	}
}

