#admin-load-user {
	.card {
		border-color: black !important;
		background-color: #00000030;
	}

	.card.border-group {
		border-color: #ce862d !important;
		background-color: #ce862d60;
	}

	.card.border-matched {
		border-color: #348653 !important;
		background-color: #34865360;
	}
}

.negative-currency {
	color: red;
}