/** DOCUMENTS **/

#attachments-table {

	table {
		width: 100% !important;
	}

	.border-spacing {
		border-collapse: separate;
		border-spacing: 0 5px;
	}

	.table td, .table th {
		border-top: none;
		border-bottom: none;
		vertical-align: middle;
		display: table-cell;
	}

	.table thead th {
		border-bottom: none;
		font-weight: bold;
		font-size: 15px;
		padding-top: 0;
	}

	.table thead th {
		border-bottom: none;
		font-weight: bold;
	}

	.table td {
		background: #f6f7f7
	}

	.separate {
		border-left: 0 solid #fff;
	}

	.table td a {
		width: 100%;
		display: block;
		color: #fff;
	}

	.table td a:hover {
		text-decoration: none;
	}

	.download-button {
		background: #bd4043;
		border-left: 5px solid #fff;
		color: #fff;
		text-align: center;
		border-left: 5px solid #fff;
		vertical-align: middle;
		padding: 0;
	}

	.link-button {
		display: block;
		width: 100%;
		padding-left: 8px;
		padding-right: 8px;
		line-height: 50px;
		-webkit-filter: brightness(100%);
	}

	.link-button:hover {
		-webkit-filter: brightness(85%);
		-webkit-transition: all 1s ease;
		-moz-transition: all 1s ease;
		-o-transition: all 1s ease;
		-ms-transition: all 1s ease;
		transition: all 1s ease;
	}

	.separate {
		margin: 50px;
	}

	.ellipsis {
		max-width: 370px;
	}
}

/** DOCUMENTS CARDS (Mobile) **/

#attachments-table.attachments-cards {

	.table td {
		padding: 10px 0 10px 10px;
		color: black;
	}

	.table td a {
		color: black;
	}

	.attachments-td-bold {
		font-weight: bolder;
	}

	.border-spacing {
		border-collapse: separate;
		border-spacing: 0 15px;
	}

	.ellipsis {
		max-width: 245px;
	}

	.general td, .general td a {
		background-color: #f6f7f7;
	}

	.pdf td, .pdf td a {
		background-color: #8bad67;
		color: white;
	}

	.text td, .text td a {
		background-color: #72afb9;
	}

	.word td, .word td a {
		background-color: #2A5492;
		color: white;
	}

	.excel td, .excel td a {
		background-color: #1D6D43;
		color: white;
	}

	.image td, .image td a {
		background-color: #9f9f9f;
	}

	.download-button {
		border-left: none;
	}
}

@media (max-width: 992px) {
	#attachments-table.attachments-cards {

		.table td {
			font-size: 12px;
			padding: 10px;
			height: 50px;
		}

		.table thead th {
			font-size: 12px;
			padding: 5px;
		}

		.attachments-td-actions {
			width: 50px;
		}

		td.attachments-td-actions {
			padding-right: 10px;
		}

		.attachments-td-info {
			width: 80%;
		}

	}

	#attachments-table .ellipsis {
		max-width: 200px;
	}
}

@media (min-width: 667px) and (max-width: 1200px) {
	#attachments-table.attachments-cards {
		.ellipsis {
			max-width: 580px;
		}
	}
}

@media (max-width: 768px) {
	#attachments-table .table .table-hide {
		display: none;
	}
	#attachments-table.attachments-cards {
		.ellipsis {
			max-width: 245px;
		}
	}
}

@media (min-width: 667px) and (max-width: 992px) {
	#attachments-table.attachments-cards {
		.ellipsis {
			max-width: 400px;
		}
	}
}

/** DOCUMENTS **/
