.submit-readings {
	padding-left: 15px;
	padding-right: 15px;
}

.contract-line-head:not(:first-of-type) {
	margin-top: 25px;
}

.submit-readings-header {
	background: #efefef;
	font-size: 18px;
	padding-top: 5px;
	padding-bottom: 5px;
	text-align: left;
	font-weight: bold;
	-webkit-border-top-left-radius: 3px;
	-webkit-border-top-right-radius: 3px;
	-moz-border-radius-topleft: 3px;
	-moz-border-radius-topright: 3px;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}

.submit-readings-inner-body {
	background: #efefef;
	padding-top: 5px;
	padding-bottom: 5px;
	border-right: 0;
	border-top: 0;
	min-height: 65px;
}

#submit-readings-form .submit-readings-inner-body {
	min-height: 85px;
}

.submit-readings-inner-header {
	display: block;
	width: 100%;
	font-weight: bold;
}

.submit-readings-inner-line {
	display: block;
	width: 100%;
	font-size: 13px;
}

.submit-readings-product-title {
	display: inline-block;
}

.submit-readings-product-attribute {
	display: inline-block;
}

#submit-readings-form input[type=text] {
	letter-spacing: 3px;
	font-family: sans-serif;
	font-size-adjust: 0.6;
}

#submit-readings-form input::placeholder {
	letter-spacing: 0;
	font-family: initial;
	font-weight: initial;
	font-size-adjust: initial;
}

.reading-input-extra {
	height: 45px;
	background: #fff;
	border: 2px solid #d3d3d3;
    border-top-right-radius: 0.3rem !important;
    border-bottom-right-radius: 0.3rem !important;
}

.reading-input-prepend
{
	height: 45px;
	min-width: 70px;
	background: #fff;
	border: 2px solid #d3d3d3;
}

.label-extra {
	float: right;
}

#submit-readings-form .mandatory {
	color: #7c7c7c;
}

@media (max-width: 768px) {
	.submit-readings-header {
		font-size: 14px;
		font-weight: normal;
	}

	.submit-readings-inner-header {
	}

	.submit-readings {
		padding-left: 0;
		padding-right: 0;
	}
}

@media (max-width: 992px) {
	.submit-readings-inner-body {
		color: white;
		background: #3D74AD;
		margin-left: -35px;
		margin-right: -35px;
		padding: 30px 20px;
	}

	.submit-readings-inner-body .form-group {
		label, .pretty .state label {
			color: inherit;
		}
	}

	.submit-readings-inner-body.supplier-border-color {
		border: none;
	}

    .submit-readings-header {
        border-bottom: 2px solid #cccccc;
    }

	.submit-readings-inner-line {
		margin-top: 10px;
	}

	.submit-readings-electricity, .submit-readings-gas {
		margin-top: 20px;
	}
}
