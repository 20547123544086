.graph-table {
}

.graph-table-header {
	font-size: 14px;
	line-height: 23px;
	font-weight: bold;
	text-align: center;
	color: #fff;
	padding-left: 10px;
	padding-right: 10px;
}

.graph-table > thead > tr > th:first-child {
	border-right: 5px solid #fff;
}

.graph-table-tr-spacer {
	line-height: 8px;
}

.graph-table-tr-spacer-2x {
	height: 10px;
}

.graph-table-row {
	transition: 0.3s;
}

.graph-table-row:hover {
	opacity: 0.7;
	//cursor:pointer;
}


/* start: Table colors */


.graph-table-verbruik-color:nth-child(odd) {
	background: rgba(176, 176, 176, 1);
	color: #fff;
}

.graph-table-verbruik-color:nth-child(even) {
	background: rgba(176, 176, 176, 0.8);
	color: #fff;
}

.graph-table-sjv-color:nth-child(odd) {
	background: rgba(244, 169, 83, 1);
	color: #fff;
}

.graph-table-sjv-color:nth-child(even) {
	background: rgba(244, 169, 83, 0.8);
	color: #fff;
}

.graph-table-sjv-color-header {
	background: rgba(244, 169, 83, 1);
	color: #fff;
}


.graph-table-teruglevering-color:nth-child(odd) {
	background: rgba(64, 142, 61, 1);
	color: #fff;
}

.graph-table-teruglevering-color:nth-child(even) {
	background: rgba(64, 142, 61, 0.8);
	color: #fff;
}

.graph-table-teruglevering-color-header {
	background: rgba(64, 142, 61, 1);
	color: #fff;
}

.graph-table-costs-color:nth-child(odd) {
	background: rgba(64, 142, 61, 0.8);
	color: #fff;
}

.graph-table-costs-color:nth-child(even) {
	background: rgba(64, 142, 61, 1);
	color: #fff;
}

.graph-table-costs-negative-color:nth-child(odd) {
	background: rgba(64, 142, 61, 0.8);
	color: #fff;
}

.graph-table-costs-negative-color:nth-child(even) {
	background: rgba(64, 142, 61, 1);
	color: #fff;
}

.graph-table-costs-color-header {
	background: rgb(17, 86, 43);
	color: #fff;
}

.graph-table-costs-totals-color {
	background: #000 !important;
}

.graph-table-costs-negative {
	background: #ab2b2b !important;
}

.graph-table-costs-estimated {
	color: #000000b0 !important;
}

.graph-table-costs-totals-color.graph-table-costs-estimated {
	color: #FFFFFF !important;
}

.graph-table-totals-costs-estimated {

}

.graph-table-pricecap-color:nth-child(odd) {
	background: rgba(198, 168, 224, 1);
	color: #fff;
}

.graph-table-pricecap-color:nth-child(even) {
	background: rgba(198, 168, 224, 0.8);
	color: #fff;
}

.graph-table-pricecap-color-header {
	background: rgba(198, 168, 224, 1);
	color: #fff;
}

//.graph-table-subheader-color:nth-child(odd) {
//    background:#000;
//    color:#fff;
//}
//.graph-table-subheader-color:nth-child(even) {
//    background:#555;
//    color:#fff;
//}

/* end: Table colors */

/* start: Table column colors */
.graph-table-months-column {
	background: #F0F0F0;
	color: #555;
	text-align: right;
	width: 120px;
	-webkit-border-top-left-radius: 5px;
	-webkit-border-bottom-left-radius: 5px;
	-moz-border-radius-topleft: 5px;
	-moz-border-radius-bottomleft: 5px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-right: 5px solid #FFF;
	font-size: 14px;
	vertical-align: middle;
	padding-right: 5px;
}

.graph-table-month {
	padding: 0 10px 5px 0;
	font-weight: bold;
	text-align: right;
	width: 120px;
	font-size: 14px;
}

.graph-table-totals-column {
	font-weight: bold;
	height: 25px;
	padding-right: 5px;
}

.graph-table-verbruik-column {
	padding: 0;
	vertical-align: middle;
	min-width: 250px;
}

.graph-table-costs-column {
	padding: 0;
	vertical-align: middle;
	min-width: 300px;
}

.graph-table-sjv-column {
	padding: 0;
	vertical-align: middle;
	min-width: 250px;
}

.graph-table-verbruik-column-gas, .graph-table-verbruik-column-elec {
	padding: 0;
	vertical-align: middle;
	min-width: 150px;
}

.graph-table-sjv-column-gas {
	padding: 0;
	vertical-align: middle;
	height: 25px;
}

.graph-table-sjv-column-elec {
	padding: 0;
	vertical-align: middle;
	height: 25px;
}

.graph-table-pricecap-column-gas, .graph-table-pricecap-column-elec {
	padding: 0;
	vertical-align: middle;
	height: 25px;
	min-width: 250px;
}

.graph-table-teruglevering-column {
	padding: 0;
	vertical-align: middle;
	min-width: 250px;
	height: 25px;
}

/* end: Table column colors */

.graph-table-body-header-wrap {
	width: 100%;
	height: 25px;
	margin-top: 2px;
	line-height: 18px;
}

.graph-table-body-subheader-wrap {
	width: 100%;
	height: 25px;
	line-height: 18px;
	color: #fff;
	font-weight: bold;
}

.graph-table-body-subfooter-wrap {
	width: 100%;
	color: #fff;
	font-weight: bold;
}

.graph-table-body-subheader-left-right-border {
	border-left: 1px solid #fff;
	border-right: 1px solid #fff
}

.graph-table-body-inner-column {
	padding-top: 3px;
	padding-bottom: 3px;
	float: left;
	width: 33%;
	height: 23px;
	text-align: center;
	font-size: 12px;
	line-height: 18px;
}

.gas-usage-content .graph-table-body-inner-column {
	width: 100%;
}

.elec-pricecap-content .graph-table-body-inner-column, .gas-pricecap-content .graph-table-body-inner-column {
	width: 50%;
}

#gas_day_table .graph-table-body-inner-column {
	width: 100%;
}

.graph-table-highchart-legend-wrap {
	margin-top: 10px;
	background: #F0F0F0;
	border-radius: 5px;
	padding: 5px;
}

.graph-table-highchart-legend-wrap h1 {
	font-size: 14px;
	margin-left: 2px;
}

.graph-table-highchart-legend-item {
	display: inline-block;
	border: 1px solid #CCCCCC;
	margin-right: 5px;
	padding-left: 5px;
	padding-right: 5px;
	border-radius: 5px;
	color: #333333;
	fill: #333333;
	font-size: 12px;
	font-weight: bold;
	cursor: pointer;
	transition: 0.3s;
	/*background:#CCCCCC;*/
}

.graph-table-highchart-legend-item:hover {
	opacity: 0.6;
}

.graph-table-highchart-legend-symbol {
	margin-top: 2px;
	margin-right: 6px;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	float: left;
}

.graph-table-highchart-bottom-wrap {
	margin: 10px;
	background: #F0F0F0;
	border-radius: 5px;
	padding: 10px;
}

.graph-table-highchart-bottom-wrap.supplier-button-color {
	background: #F0F0F0 !important;
	color: inherit;
}

@media only screen and (max-width: 768px) {
	.graph-table-verbruik-column-gas {
		min-width: 85px;
	}

	.graph-table-months-column
	{
		font-size: 12px;
		padding: 0 5px 0 5px;
	}

	.graph-table-costs-column {
		min-width: 220px;
	}

	.info-icon-mobile-negative-offset {
		margin-left: -35px;
		color: white;
	}

	.graph-table-verbruik-column-gas, .graph-table-verbruik-column-elec {
		min-width: 70px;
	}

	.graph-table-pricecap-column-gas, .graph-table-pricecap-column-elec {
		min-width: 140px;
	}

}

@media (max-width: 992px) {
	.graph-table-highchart-bottom-wrap {
		margin: 30px -30px -30px -10px;
		padding: 30px;
		border-radius: 0;
	}

	.graph-table-highchart-bottom-wrap.supplier-button-color {
		background: #8c8c8c !important;
	}

	.graph-table-highchart-bottom-wrap * {
		color: white;
	}
}
