/* mobile menu styling */

/* ================== Desktop Extra CSS ================== */

.wsmobileheader {
	display: none;
}


.content-padding {
	padding: 25px 45px 25px 45px;
}

#navMenu {
	display: none;
	position: relative;
	width: 100%;
	height: 100%;
	padding-top: 70px;
	top: -1000px;
	z-index: 1001;
}

#navMenu ul.wsmenu-list, #personalMenu ul.wsmenu-list {
	background-color: white;
}

#navMenu a, #personalMenu a {
	color: black;
}

#personalMenu {
	display: none;
	position: relative;
	width: 100%;
	height: 100%;
	padding-top: 70px;
	top: -500px;
	z-index: 1001;
}

#navMenu form, #personalMenu form, .content-card form {
	margin-top: 0;
}

#slide-down {
	display: none;
	background-color: transparent;
	position: absolute;
	top: -1000px;
	z-index: 500;
	left: 0;
	margin-left: -30px;
	width: calc(100% + 60px);
}

#slide-down .form-group label {
	color: white;
}

#slide-down button.slide-down-color {
	background-color: white !important;
	color: black;
}

#slide-down .action-icon {
	right: 28px;
	height: 30px;
	width: 30px;
	top: -5px;
}

#slide-down .slide-down-wrapper {
	padding-top: 20px;
	padding-bottom: 30px;
	top: 55px;
}

.wsmobileheader .navbar-light .navbar-toggler, .wsmobileheader .navbar-dark .navbar-toggler {
	border: none;
}

.wsmobileheader .menu-active {
	display: none;
}

.menu {
	padding: 40px 0 10px 0;
	margin: 0;
	background-color: white;
	box-shadow: 0 1px 10px rgba(80, 80, 80, 0.5)
}

.menu a {
	text-decoration: none;
}

.menu .menu-icon {
	display: inline-block;
	margin-right: 10px;
	width: 20px;
	font-weight: bold;
	font-size: larger;
}

.menu .menu-item {
	font-weight: bold;
	font-size: larger;
}

.menu .sub-menu .menu-item {
	font-weight: normal;
	font-size: large;
}

.menu ul {
	position: relative;
	list-style: none;
	background-color: white;
	margin: 0;
	padding: 0;
}

.menu li {
	min-height: 70px;
	list-style: none;
}

.menu>li>* {
	padding-left: 30px;
}

.menu li>ul {
	display: none;
}

.menu li:hover>ul {
	display: block;
}

.menu .menu-arrow {
	position: absolute;
	right: 30px;
}

.menu .menu-arrow .arrow-active {
	display: none;
}

.menu li:not(.active):hover .menu-arrow .arrow-inactive {
	display: none;
}

.menu li:not(.active):hover .menu-arrow .arrow-active {
	display: block;
}

.menu .sub-menu {
	background-color: #eeeeee;
	padding: 30px 0 0 40px;
	margin: 25px 0 30px 0;
}

.menu .sub-menu li {
	padding: 0;
}

.menu li.active .sub-menu {
	display: block;
}

/*==============================================================================
                              Start Mobile CSS
===============================================================================*/

/* ================== Mobile Menu Change Brake Point ================== */
@media only screen and (max-width: 992px) {

	.wsmainfull {
		position: absolute;
	}

	.desktoplogo {
		display: none;
	}

	.wsmobileheader {
		width: 100%;
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1010;
		height: 70px;
		background-color: white;
		text-align: center;
		border-bottom: 1px solid #d3d3d3;
	}

	.wsmobileheader .smllogo {
		display: inline-block;
		margin-top: 12px;
	}
}
