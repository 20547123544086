.form-control {
	/* Remove First */
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	border-radius: 0;
	border: 2px solid #d3d3d3;
	outline: none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	color: #464646;

	sup {
		color: red;
		font-size: 12px;
		margin: 0;
		padding: 0;
		vertical-align: top;
		position: relative;
		top: 0;
	}
}

.form-control:disabled,
.form-control[readonly] {
	color: #3f3f3f;
}

.form-control-lg {
	padding: 0.5rem 2em 0.5em 1em;
}

.form-control:focus {
	border-color: #a8a8a8;
	box-shadow: none;
}

.form-control::-webkit-input-placeholder { /* Edge */
	color: lightgrey !important;
}

.form-control:-ms-input-placeholder { /* Internet Explorer */
	color: lightgrey !important;
}

.form-control::placeholder {
	color: lightgrey !important;
}

input[type=file] {
	border: 2px solid #5FAA1A99;
	padding: 5px;
	height: auto;
}

input[type=file]::-ms-browse {
	color: white;
	padding: .2em .4em;
	border: 2px solid transparent;
	background-color: #5FAA1A99;
}

input[type=file]::-webkit-file-upload-button {
	color: white;
	padding: .2em .4em;
	border: 2px solid transparent;
	background-color: #5FAA1A99;
	transition: 1s;
}

input[type=file]::file-selector-button {
	color: white;
	font-size: 1rem;
	padding: .2em .4em;
	border: 2px solid transparent;
	background-color: #5FAA1A99;
	transition: 1s;
}

input[type=file]::-ms-browse:hover {
	background-color: #46810E99;
}

input[type=file]::-webkit-file-upload-button:hover {
	background-color: #46810E99;
}

input[type=file]::file-selector-button:hover {
	background-color: #46810E99;
}

select.form-control {
	background-image: linear-gradient(45deg, transparent 50%, #e9e9e9 60%),
	linear-gradient(135deg, #e9e9e9 40%, transparent 50%) !important;
	background-position: calc(100% - 20px) 17px,
		calc(100% - 10px) 17px,
		100% 0;
		background-size: 10px 10px,
		10px 10px;
	background-repeat: no-repeat;
	-webkit-appearance: none;
	-moz-appearance: none;
}

select option {
	text-align-last: center;
	text-align: center;
	-ms-text-align-last: center;
	-moz-text-align-last: center;
}

.switches {
	font-size: 12px;

	.form-group {
		margin-bottom: 5px;
		display: inline-block;
		margin-right: 10px;
	}
}

.form-group {
	margin-bottom: 10px;

	label {
		text-transform: uppercase;
		margin: 0 0 8px 0;
		font-size: 12px;
		color: #7c7c7c;
		font-weight: normal;
	}

	.pretty .state label {
		color: #000;
		font-size: inherit;
		text-transform: none;
	}

	.line-error {
		width: 100%;
		padding: 5px;
		padding-top: 2px;
		padding-bottom: 2px;
		color: #721c24;
		background: #f8d7da;
		border: 1px solid #f5c6cb;
		font-size: 12px;
	}

	.input-info {
		font-size: 12px;
		color: #666;
	}

	.admin-text, label .admin-text, .pretty.admin-text .state label {
		color: #b7b7b7 !important;
	}

	>.form-menu {
		width: 100% !important;

		.btn {
			text-align: left;
			padding: 10px 20px;
		}
	}

	sup.required {
		font-size: 100% !important;
		top: -0.2em;
	}
}

.form-group-grid {
	display: grid;
	grid-template-columns: max-content max-content;
	// grid-gap:5px;
	align-items: center;

	label {
		text-align:right;
		text-transform: unset;
		white-space: nowrap;
		font-size: inherit;
		margin: 0 10px 0 0;
	}

	.form-control-lg {
		padding: 0.5rem 1em 0.5em 1em;
	}
}

.form-group-border-with-title {
	padding-left: 15px;
	padding-right: 15px;
	margin-top: 10px;
}

.bootstrap-touchspin-down, .bootstrap-touchspin-up {
	-webkit-border-radius: 0 !important;
	-moz-border-radius: 0 !important;
	border-radius: 0 !important;
}

.input-group-text {
	border: none;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
	padding-right: calc(1.6em + 0.75rem) !important;
}

.form-menu .btn.is-invalid {
	border: 2px solid #e3342f;
}

.form-menu .btn {
	background-color: white !important;
	border: 2px solid #d3d3d3;
	color: black;
}

.datepicker-wrapper {
	min-height: 250px;
	min-width: 230px;
	margin-right: 10px;
	float: left;
}

.datepicker.datepicker-wrapper, .datepicker.datepicker {
	padding: 5px 10px;
}

.datepicker table {
	width: 100%;
}

.datepicker table thead tr:nth-child(2) {
	background-color: #f0f0f0;
}

.datepicker .datepicker-switch:hover, .datepicker .next:hover, .datepicker .prev:hover, .datepicker tfoot tr th:hover {
	background: #c4c4c4;
}

.datepicker-inline {
	display: inline-block;
	border: 1px solid lightgrey;
}

.datepicker .datepicker-title
{
	font-size: larger;
	font-weight: bold;
}

.datepicker table tr td, .datepicker table tr th {
	text-align: center;
	width: 30px;
	height: 30px;
	border-radius: 4px;
	border: none;
	border-top-color: currentcolor;
	border-right-color: currentcolor;
	border-bottom-color: currentcolor;
	border-left-color: currentcolor;
	vertical-align: middle;
}

#invoices-edit-unit-table .invoice-td-percentage .input-group {
	width: 115px;
}

#invoices-edit-unit-table input[type=text].form-control-lg {
	padding: 0.5rem 0.5rem;
}
