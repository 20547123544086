.readings {
	padding: 0;
}

.readings-product-wrap {
	padding: 10px;
	background: #efefef;
	border: 1px #dedede solid;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	margin-top: 25px;
	margin-bottom: 30px;
}

.readings-product-attribute-title {
	font-weight: bold;
	font-size: 12px;
	overflow: hidden;
}

.readings-product-attribute {
	font-size: 12px;
	overflow: hidden;
	margin-left: 5px;
}

.readings-product-row {
	padding: 0;
	margin-bottom: 5px;
}

.readings-product-icon {
	font-size: 50px;
	height: 40px;
}

.readings-product-icon-title {
	display: block;
	width: 100%;
	font-size: 12px;
}

.readings-chart {
	width: 100%;
	min-height: 400px;
}

@media (max-width: 1200px) {
	.readings {
	}
}

@media (max-width: 768px) {
	.readings-product-attribute-title, .readings-product-attribute {
		font-size: 13px;
		margin-left: 0;
		vertical-align: middle;
	}
	.readings-product-attribute-title {
		margin-left: 5px;
	}
	#dropdown_year {
		margin-top: 15px;
	}
}

@media (max-width: 992px) {
	.readings-product-row {
		margin: 0 0 5px 0;
		padding: 5px 0;
	}

	.readings-product-wrap {
		background: #efefef;
		border: none;
		border-radius: unset;
		margin-left: -30px;
		margin-right: -30px;
		padding: 30px;
	}

	.readings-product-row:nth-of-type(odd) {

	}

	.readings-product-row:nth-of-type(even) {
		background-color: rgba(255,255,255,0.6);
	}

	#elec_graph, #gas_graph {
		border: none;
		background: transparent;
	}
}
