/*!* http://meyerweb.com/eric/tools/css/reset/*/
/*v2.0 | 20110126*/
/*License: none (public domain)*/
/**!*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {

	font: inherit;
	font-size: 100%;
	vertical-align: baseline;
}

//No blue borders (chrome)
.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
	outline: 0 !important;
}

*:focus {
	outline: none !important
}

.select-style select:focus {
	outline: none;
}

.no-padding {
	padding: 0;
}

.ellipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.col-font-bold {
	font-weight: bold
}

.col-no-flex {
	flex-grow: unset;
}

.padding-top-bottom-5 {
	padding-top: 5px;
	padding-bottom: 5px;
}

.p-0 {
	padding: 0;
}

.p-3 {
	padding: 3px;
}

.p-5 {
	padding: 5px;
}

.p-10 {
	padding: 10px;
}

.p-15 {
	padding: 15px;
}

.p-20 {
	padding: 20px;
}

.p-25 {
	padding: 25px;
}

.p-30 {
	padding: 30px;
}

.p-35 {
	padding: 35px;
}

.p-40 {
	padding: 40px;
}

.p-45 {
	padding: 45px;
}

.p-50 {
	padding: 50px;
}

.pt-0 {
	padding-top: 0;
}

.pt-3 {
	padding-top: 3px;
}

.pt-5 {
	padding-top: 5px;
}

.pt-10 {
	padding-top: 10px;
}

.pt-15 {
	padding-top: 15px;
}

.pt-20 {
	padding-top: 20px;
}

.pt-25 {
	padding-top: 25px;
}

.pt-30 {
	padding-top: 30px;
}

.pt-35 {
	padding-top: 35px;
}

.pt-40 {
	padding-top: 40px;
}

.pt-45 {
	padding-top: 45px;
}

.pt-50 {
	padding-top: 50px;
}

.pr-0 {
	padding-right: 0;
}

.pr-3 {
	padding-right: 3px;
}

.pr-5 {
	padding-right: 5px;
}

.pr-10 {
	padding-right: 10px;
}

.pr-15 {
	padding-right: 15px;
}

.pr-20 {
	padding-right: 20px;
}

.pr-25 {
	padding-right: 25px;
}

.pr-30 {
	padding-right: 30px;
}

.pr-35 {
	padding-right: 35px;
}

.pr-40 {
	padding-right: 40px;
}

.pr-45 {
	padding-right: 45px;
}

.pr-50 {
	padding-right: 50px;
}

.pb-0 {
	padding-bottom: 0;
}

.pb-3 {
	padding-bottom: 3px;
}

.pb-5 {
	padding-bottom: 5px;
}

.pb-10 {
	padding-bottom: 10px;
}

.pb-15 {
	padding-bottom: 15px;
}

.pb-20 {
	padding-bottom: 20px;
}

.pb-25 {
	padding-bottom: 25px;
}

.pb-30 {
	padding-bottom: 30px;
}

.pb-35 {
	padding-bottom: 35px;
}

.pb-40 {
	padding-bottom: 40px;
}

.pb-45 {
	padding-bottom: 45px;
}

.pb-50 {
	padding-bottom: 50px;
}

.pl-0 {
	padding-left: 0;
}

.pl-3 {
	padding-left: 3px;
}

.pl-5 {
	padding-left: 5px;
}

.pl-10 {
	padding-left: 10px;
}

.pl-15 {
	padding-left: 15px;
}

.pl-20 {
	padding-left: 20px;
}

.pl-25 {
	padding-left: 25px;
}

.pl-30 {
	padding-left: 30px;
}

.pl-35 {
	padding-left: 35px;
}

.pl-40 {
	padding-left: 40px;
}

.pl-45 {
	padding-left: 45px;
}

.pl-50 {
	padding-left: 50px;
}

.m-0 {
	margin: 0;
}

.m-3 {
	margin: 3px;
}

.m-5 {
	margin: 5px;
}

.m-10 {
	margin: 10px;
}

.m-15 {
	margin: 15px;
}

.m-20 {
	margin: 20px;
}

.m-25 {
	margin: 25px;
}

.m-30 {
	margin: 30px;
}

.m-35 {
	margin: 35px;
}

.m-40 {
	margin: 40px;
}

.m-45 {
	margin: 45px;
}

.m-50 {
	margin: 50px;
}

.mtt-0 {
	margin-top: 0;
}

.mtt-3 {
	margin-top: 3px;
}

.mtt-5 {
	margin-top: 5px;
}

.mtt-10 {
	margin-top: 10px;
}

.mtt-15 {
	margin-top: 15px;
}

.mtt-20 {
	margin-top: 20px;
}

.mtt-25 {
	margin-top: 25px;
}

.mtt-30 {
	margin-top: 30px;
}

.mtt-35 {
	margin-top: 35px;
}

.mtt-40 {
	margin-top: 40px;
}

.mtt-45 {
	margin-top: 45px;
}

.mtt-50 {
	margin-top: 50px;
}

.mrr-0 {
	margin-right: 0;
}

.mrr-3 {
	margin-right: 3px;
}

.mrr-5 {
	margin-right: 5px;
}

.mrr-10 {
	margin-right: 10px;
}

.mrr-15 {
	margin-right: 15px;
}

.mrr-20 {
	margin-right: 20px;
}

.mrr-25 {
	margin-right: 25px;
}

.mrr-30 {
	margin-right: 30px;
}

.mrr-35 {
	margin-right: 35px;
}

.mrr-40 {
	margin-right: 40px;
}

.mrr-45 {
	margin-right: 45px;
}

.mrr-50 {
	margin-right: 50px;
}

.mbb-0 {
	margin-bottom: 0;
}

.mbb-3 {
	margin-bottom: 3px;
}

.mb-5 {
	margin-bottom: 5px;
}

.mbb-10 {
	margin-bottom: 10px;
}

.mbb-15 {
	margin-bottom: 15px;
}

.mbb-20 {
	margin-bottom: 20px;
}

.mbb-25 {
	margin-bottom: 25px;
}

.mbb-30 {
	margin-bottom: 30px;
}

.mbb-35 {
	margin-bottom: 35px;
}

.mbb-40 {
	margin-bottom: 40px;
}

.mbb-45 {
	margin-bottom: 45px;
}

.mbb-50 {
	margin-bottom: 50px;
}

.mll-0 {
	margin-left: 0;
}

.mll-3 {
	margin-left: 3px;
}

.mll-5 {
	margin-left: 5px;
}

.mll-10 {
	margin-left: 10px;
}

.mll-15 {
	margin-left: 15px;
}

.mll-20 {
	margin-left: 20px;
}

.mll-25 {
	margin-left: 25px;
}

.mll-30 {
	margin-left: 30px;
}

.mll-35 {
	margin-left: 35px;
}

.mll-40 {
	margin-left: 40px;
}

.mll-45 {
	margin-left: 45px;
}

.mll-50 {
	margin-left: 50px;
}

.span-100 {
	display: block;
	width: 100%
}

.cursor {
	cursor: pointer;
}

.font-10 {
	font-size: 10px !important;
}

.font-11 {
	font-size: 11px !important;
}

.font-12 {
	font-size: 12px !important;
}

.font-13 {
	font-size: 13px !important;
}

.font-14 {
	font-size: 14px !important;
}

.font-15 {
	font-size: 15px !important;
}

.font-16 {
	font-size: 16px !important;
}

.font-17 {
	font-size: 17px !important;
}

.font-18 {
	font-size: 18px !important;
}

.font-19 {
	font-size: 19px !important;
}

.font-20 {
	font-size: 20px !important;
}

.text-upper {
	text-transform: uppercase;
}

.no-border-radius {
	-webkit-border-radius: 0 !important;
	-moz-border-radius: 0 !important;
	border-radius: 0 !important;
}

.grayscale {
	-webkit-filter: grayscale(1);
}
