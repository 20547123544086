.debug {
	text-align: left;
}

.debug_div {
	display: inline-flex;
	position: relative;
	width: 100%;
	z-index: 999;
}

.debug_div pre {
	margin-bottom: 0;
}

.debug h2 {
	color: #999;
	text-shadow: 2px 2px 4px #888;
}

.debug h3 {
	color: #888;
	text-shadow: 2px 2px 4px #888;
}

pre.debug {
	color: #848484;
	font-family: monospace;
	font-feature-settings: initial;
	font-kerning: initial;
	font-language-override: initial;
	font-size: initial;
	font-size-adjust: initial;
	font-stretch: initial;
	font-style: initial;
	font-synthesis: initial;
	font-variant: initial;
	font-weight: initial;
	line-height: initial;
}

.debug_struct {
	color: #2d3274;
}

.debug_varname {
	color: #742325;
}

.debug_varvalue {
	color: #317a31;
}

.debug_comment {
	color: #BCB601;
}

.debug hr {
	border-color: #d9d9d9;
	border-image: none;
	border-style: dashed none;
	border-width: 1px 0;
	margin: 20px 0 5px 0;
}

.debug_normal {
	overflow: hidden;
	margin-left: 30px;
}

.debug_collapsable {
	cursor: pointer;
	margin-left: 30px;
}

.debug_collapsable:hover {
	font-weight: 800;
}

.debug_closed {
	height: 16px;
	overflow: hidden;
}

.debug_opened {
}

.debug_closed:before {
	content: "[+]";
	float: left;
	margin-left: -30px;
	position: absolute;
}

.debug_opened:before {
	content: "[-]";
	float: left;
	margin-left: -30px;
	position: absolute;
}

.debug_openall, .debug_closeall {
	cursor: pointer;
}

.debug_openall:hover {
	font-weight: 800;
}

.debug_openall:hover:after {
	content: " [+]";
	font-weight: normal;
}

.debug_closeall:hover:after {
	content: " [-]";
	font-weight: normal;
}

abbr.disabled {
	color: #CCCCCC;
}
