.btn {
	border-radius: 0;
	border: 2px solid transparent;
}

.btn:hover {
	-moz-transition: all .2s ease-in;
	-o-transition: all .2s ease-in;
	-webkit-transition: all .2s ease-in;
	transition: all .2s ease-in;
	filter: brightness(90%);
}

.btn:focus, .btn.focus {
	box-shadow: none;
}

.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active, .show > .btn.dropdown-toggle {
	box-shadow: none;
}

.btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus, .show > .btn.dropdown-toggle:focus {
	box-shadow: none;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
	box-shadow: none;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
	color: #3760a9;
	background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
	border: none;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
	box-shadow: none;
}

.btn-only-bordered {
	background-color: inherit;
	border: 2px solid black;
	color: black;
}

.btn-only-bordered:hover, .btn-only-bordered:active, .btn-only-bordered:focus {
	background-color: inherit;
}

.btn-center {
	margin-left: auto;
	margin-right: auto;
	width: max-content;
}

.btn-right {
	top: 0;
	right: 0;
	position: absolute;
	margin-top: 0
}

.btn-primary:hover, .btn-primary:disabled {
	border-color: transparent;
}

.btn-primary.disabled,
.btn-primary:disabled {
	border-color: unset !important;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
	color: #fff;
	background-color: #6e6e6e;
	border-color: #565656;
}

.btn-primary:focus, .btn-primary.focus {
	color: #fff;
	background-color: #6e6e6e;
	border-color: #565656;
	box-shadow: 0 0 0 0.1rem rgba(110, 110, 110, 0.5);
}

.btn-block + .btn-block {
	margin-top: unset;
}

.btn:disabled:hover {
	transition: unset;
	filter: unset;
}

.dropdown-toggle:after {
    vertical-align: 0.15em;
}
