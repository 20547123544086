.overview-customer-help {
	margin: 20px 0;
	padding: 35px 20px;
	color: white;
	background-color: #7c7c7c;
}

.overview-customer-help h2 {
	margin-bottom: 30px;
}

.overview-customer-help-link {
	position: relative;
	margin-top: 20px;
	margin-bottom: 10px;
}

.overview-customer-help-link a {
	color: white;
	text-decoration: none;
}

.overview-customer-help-link a:hover {
	color: #CCCCCC;
	text-decoration: underline;
}

.overview-customer-help-link svg, .overview-customer-help-link i {
	position: absolute;
	top: 0;
	right: 0;
	width: 20px
}

.overview-customer-help-text {
	margin-right: 25px;
}

.overview-readings, .overview-debit, .overview-day-prices {
	background-color: white;
	color: black;
	padding: 30px;

	svg {
		color: #CCCCCC;
		font-size: 22px;
	}
}

.overview-debit, .overview-day-prices {
	margin-top: 40px;
}

.overview .content-card {
	padding: 50px 30px;
}

#customer-cards .form-group {
	margin-bottom: 0;
}

.customer-card-attributes {
	padding-bottom: 20px;
}

.customer-card-attributes:last-child {
	padding-bottom: 0;
}

.customer-card-attribute-title {
	display: inline-block;
	vertical-align: top;
	margin-right: 5px;
	font-weight: bold;
}

.customer-card-attribute {
	display: inline-block;
}

.customer-card-status {
	position: absolute;
	width: 15px;
	height: 20px;
	left: -15px;
	top: 10px;
}

.customer-card-status-active {
	background-color: darkgreen;
}

.customer-card-status-inactive {
	background-color: darkred;
}

.customer-card-inactive {
	opacity: 0.5;
}
