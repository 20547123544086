.tabset > input[type="radio"] {
	position: absolute;
	left: -200vw;
}

.tabset .tab-panel {
	display: none;
}

.tabset > input[type="radio"]:first-child:checked ~ .tab-panels > .tab-panel:first-child,
.tabset > input[type="radio"]:nth-child(3):checked ~ .tab-panels > .tab-panel:nth-child(2),
.tabset > input[type="radio"]:nth-child(5):checked ~ .tab-panels > .tab-panel:nth-child(3),
.tabset > input[type="radio"]:nth-child(7):checked ~ .tab-panels > .tab-panel:nth-child(4),
.tabset > input[type="radio"]:nth-child(9):checked ~ .tab-panels > .tab-panel:nth-child(5),
.tabset > input[type="radio"]:nth-child(11):checked ~ .tab-panels > .tab-panel:nth-child(6) {
	display: block;
}

.tabset > label {
	position: relative;
	display: inline-block;
	padding: 5px 5px 10px;
	margin-bottom: 1px;
	margin-top: 2px;
	border: 1px solid #EFEFEF;
	border-bottom: 0;
	cursor: pointer;
	font-weight: 600;
	background: #fff;
}

.tabset > label::after {
	content: "";
	position: absolute;
	left: 5px;
	bottom: 5px;
	width: 22px;
	height: 4px;
	background: #8d8d8d;
}

.tabset > label:hover,
.tabset > input[type="radio"]:focus + label {
	color: #06c;
}

.tabset > label:hover::after,
.tabset > input[type="radio"]:focus + label::after,
.tabset > input[type="radio"]:checked + label::after {
	background: forestgreen;
}

.tabset > input[type="radio"]:checked + label {
	border-color: #ccc;
	border-bottom: 2px solid #fff;
	margin-bottom: -2px;
}

.tab-panel {
	padding: 0;
	/*border-top: 1px solid #ccc;*/
}

.tabset > label.tab-hidden {
	background: #dbdbdb;
	color: #8a8a8a;
}

@media (max-width: 992px) {
	.tabset > label {
		border: 1px solid #EFEFEF;
		margin-top: 5px;
	}

	.tabset > input[type="radio"]:checked + label {
		border: 1px solid #ccc;
	}

	.tab-panels {
		margin-top: 10px;
	}

	.btn-sm,
	.btn-group-sm > .btn {
		padding: 0.25rem 0.35rem;
	}
}
