.admin-options-bar {
	margin-bottom: 40px;
}

.admin-options-bar .row {
	background-color: #d3d3d3;
	border: 2px dashed #e3f2fd;
}

.admin-options-bar .checkbox-menu li a label {
	display: block;
	padding: 3px 10px;
	clear: both;
	font-weight: normal;
	line-height: 1.42857143;
	color: #333;
	white-space: nowrap;
	margin: 0;
	transition: background-color .4s ease;
}

.admin-options-bar .checkbox-menu li a input {
	margin: 0 5px;
	top: 2px;
	position: relative;
}

.admin-options-bar .checkbox-menu li a.active label {
	background-color: #cbcbff;
	font-weight: bold;
}

.admin-options-bar .checkbox-menu li a label:hover,
.admin-options-bar .checkbox-menu li a label:focus {
	background-color: #f5f5f5;
}

.admin-options-bar .checkbox-menu li a.active a label:hover,
.admin-options-bar .checkbox-menu li a.active a label:focus {
	background-color: #b8b8ff;
}

.admin-options-bar .dropdown {
	display: inline;
	margin: 10px 0;
}

.dropdown-menu-admin hr {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

.dropdown-menu-admin .p4_label {
	margin-right: 30px;
}

.dropdown-menu-admin .p4_info {
	float: right;
}

.dropdown-submenu {
	position: relative;
}

.dropdown-submenu > .dropdown-menu {
	top: 0;
	left: 100%;
	margin-top: -6px;
	-webkit-border-radius: 0 6px 6px 0;
	-moz-border-radius: 0 6px 6px 0;
	border-radius: 0 6px 6px 0;
}

.dropdown-submenu:hover > .dropdown-menu {
	display: block;
}

.dropdown-submenu > a:after {
	display: block;
	content: " ";
	float: right;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
	border-width: 5px 0 5px 5px;
	border-left-color: #ccc;
	margin-top: 5px;
	margin-right: -10px;
}

.dropdown-submenu:hover > a:after {
	border-left-color: #fff;
}

.dropdown-submenu.pull-left {
	float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
	left: -100%;
	margin-left: 10px;
	-webkit-border-radius: 6px 0 6px 6px;
	-moz-border-radius: 6px 0 6px 6px;
	border-radius: 6px 0 6px 6px;
}

#adminBar .navbar-nav .dropdown-menu {
	padding-bottom: 20px;
}

#adminBar .btn-primary {
	color: white;
	background-color: #838383;
	border-radius: 5px;
}

#adminBar .navbar-nav .nav-link {
    background-color: white;
    border: 2px solid lightgray;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
}

.inline-edit-bar {
	padding: 15px;
	cursor:pointer;
	display: inline-block;
}

#adminBar .navbar-nav .dropdown-menu .dropdown-item.demo-mode {
	color: grey !important;
}

#adminBar .navbar-nav .dropdown-menu .dropdown-item.demo-mode.demo-mode-preferred {
	color: black !important;
}

@media only screen and (max-width: 1200px) {
	.admin-options-bar input[type="search"] {
		width: 65%;
	}
}

@media only screen and (max-width: 992px) {
	.admin-options-bar {
		margin-bottom: 0;
	}
	.admin-options-bar .row {
		padding: 0 10px;
	}
	.inline-edit-bar {
		padding: 0 0 15px 0;
	}
	#adminBar .dropdown-menu {
		margin-top: 12px;
	}
	#adminBar .navbar-nav .dropdown-menu {
		margin-top: 0;
	}
	#adminBar .navbar-nav .dropdown-menu .dropdown-item:first-child {
		border-top: 1px solid #CCCCCC;
		padding-top: 10px;
	}
	#adminBar .navbar-nav .dropdown.show .nav-link {
		background-color: unset;
		border: 2px solid transparent;
	}
}
