
#salesperson-fees form #options {
	display: inline-block;
	float: left;
	height: 280px;
	width: 370px;
	position: absolute;
	margin: 5px;
}

#salesperson-fees form #options button[type=submit] {
	bottom: 0;
	position: absolute;
	width: max-content;
}

#salesperson-fees .table .single_fuel {
	background-color: rgba(186, 133, 133, 0.3)
}

#salesperson-fees .table .dual_fuel {
	background-color: rgba(141, 188, 157, 0.3)
}

#salesperson-fees .total_fee {
}

#salesperson-fees .download-box {
	border: 1px solid lightgrey;
	border-radius: 4px;
	padding: 5px 10px;
	margin: 10px 0 0 0;
	line-height: 22px;
}

#salesperson-contracts-changes {
	td, th {
		text-align: center;
		vertical-align: middle;
		min-height: 40px;
		height: 65px;
	}

	.status_none {
		background-color: #EFEFEF;
		opacity: 0.5;
	}

	.status_quotation {
		background-color: rgba(188, 210, 196, 0.5);
	}
	.status_switchedin, .status_waitingformeter {
		background-color: rgba(156, 189, 167, 0.5);
	}
	.status_customerapproved {
		background-color: rgba(171, 158, 193, 0.5);
	}
	.status_switchsendin {
		background-color: rgba(158, 166, 193, 0.5);
	}
	.status_switchsendout {
		background-color: rgba(182, 116, 116, 0.5);
	}
	.status_dispute {
		background-color: rgba(158, 190, 193, 0.5);
	}
	.status_customerdisapproved, .status_preendcontract {
		background-color: rgba(193, 158, 158, 0.5);
	}
	.status_movedout, .status_closed {
		background-color: rgba(180, 97, 97, 0.5);
	}

	.multiple_states {
		text-decoration-line: underline;
		text-decoration-style: dashed;
		cursor: pointer;
	}
}

@media (max-width: 992px) {
	.salesperson-loaded-banner {
		top: 70px;
		height: 55px;
	}
}
